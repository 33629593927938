import React from "react";
import PropTypes from "prop-types";
import { Icon } from "antd";
const IconCP = ({ icon, title, style, className }) => {
  return (
    <span className={className} style={style} title={title}>
      <Icon type={icon} />
    </span>
  );
};

IconCP.propTypes = {
  style: PropTypes.object,
  icon: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string
};

export default IconCP;
