import React, { Component } from "react";
import { Form, Input, Button, Spin, Alert, Divider, Radio } from "antd";
import PropTypes from "prop-types";

const FORM_STATUS = {
  OPEN: "OPEN",
  SUBMITTING: "SUBMITTING",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_ERROR: "SUBMIT_ERROR"
};

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};

const fcasServices = [
  "Raisereg",
  "Lowerreg",
  "Raise5min",
  "Lower5min",
  "Raise60sec",
  "Lower60sec",
  "Raise6sec",
  "Lower6sec"
];

class AssumptionsForm extends Component {
  state = {
    confirmDirty: false,
    formStatus: FORM_STATUS.OPEN
  };

  parseField = (field, value) => {
    const { form } = this.props;
    const fieldInstance = form.getFieldInstance(field);
    const type =
      fieldInstance && fieldInstance.props && fieldInstance.props.type;
    if (type === "number") return parseFloat(value);
    return value;
  };

  handleSubmit = e => {
    e.preventDefault();
    const { pocAPI, onFormSubmitSuccess } = this.props;
    const self = this;
    this.props.form.validateFieldsAndScroll((err, fieldsValue) => {
      if (!err) {
        self.setState({
          formStatus: FORM_STATUS.SUBMITTING
        });

        //parse floats from strings
        let parsedValues = {};
        Object.keys(fieldsValue).forEach(field => {
          parsedValues[field] = this.parseField(field, fieldsValue[field]);
        });

        const assumptionsName = fieldsValue["name"];
        pocAPI
          .put(
            `/v1/assumptions/${assumptionsName}`,
            JSON.stringify(parsedValues, null, 0),
            {
              headers: {
                "Content-Type": "application/json"
              }
            }
          )
          .then(function(response) {
            self.setState(
              {
                formStatus: FORM_STATUS.SUBMIT_SUCCESS
              },
              () => onFormSubmitSuccess()
            );
          })
          .catch(function(error) {
            console.error("Error submitting assumptions", error);
            self.setState({
              formStatus: FORM_STATUS.SUBMIT_ERROR
            });
          });
      }
    });
  };

  // handleConfirmBlur = e => {
  //   const value = e.target.value;
  //   this.setState({ confirmDirty: this.state.confirmDirty || !!value });
  // };

  getInitialValueForField = field => {
    const { assumptions } = this.props;
    if (assumptions && assumptions[field] !== undefined)
      return assumptions[field];
    //console.warn("Unable to find value for field", field);
    return "";
  };

  isFieldDisabled = () => {
    const { formStatus } = this.state;
    return (
      formStatus === FORM_STATUS.SUBMITTING ||
      formStatus === FORM_STATUS.SUBMIT_SUCCESS
    );
  };

  renderFormItem = (
    label,
    field,
    type,
    ruleProps,
    extra,
    addOnBefore,
    addOnAfter
  ) => {
    const { form: { getFieldDecorator } } = this.props;

    return (
      <Form.Item
        key={field}
        {...formItemLayout}
        style={{ marginBottom: "8px" }}
        label={label}
        extra={extra}
      >
        {getFieldDecorator(field, {
          initialValue: this.getInitialValueForField(field),
          rules: [
            {
              required: true,
              message: "Value is required"
            },
            {
              ...ruleProps
            }
          ]
        })(
          <Input
            addonBefore={addOnBefore}
            addonAfter={addOnAfter}
            disabled={this.isFieldDisabled()}
            type={type}
          />
        )}
      </Form.Item>
    );
  };

  renderFormRadio = (label, field, items) => {
    const { form: { getFieldDecorator } } = this.props;
    const disabled = this.isFieldDisabled();

    return (
      <Form.Item
        key={field}
        {...formItemLayout}
        style={{ marginBottom: "8px" }}
        label={label}
      >
        {getFieldDecorator(field, {
          initialValue: this.getInitialValueForField(field),
          rules: [
            {
              required: true,
              message: "Value is required"
            }
          ]
        })(
          <Radio.Group disabled={disabled}>
            {items.map(item => (
              <Radio.Button
                disabled={disabled}
                key={item.value}
                value={item.value}
              >
                {item.text}
              </Radio.Button>
            ))}
          </Radio.Group>
        )}
      </Form.Item>
    );
  };

  renderSection = title => {
    return <Divider orientation="left">{title}</Divider>;
  };

  getValue = field => {
    return this.props.form.getFieldValue(field);
  };

  getNetEfficiency = () => {
    const cycleCostPercentage = this.getValue("energy_cycle_cost_percent");
    const chargeEfficiency = this.getValue("energy_charge_efficiency");
    const dischargeEfficiency = this.getValue("energy_discharge_efficiency");
    const chargeMlf = this.getValue("energy_charge_mlf");
    const dischargeMlf = this.getValue("energy_discharge_mlf");
    if (
      cycleCostPercentage !== undefined &&
      chargeEfficiency !== undefined &&
      dischargeEfficiency !== undefined &&
      chargeMlf !== undefined &&
      dischargeMlf !== undefined
    ) {
      return `${(
        (1 - cycleCostPercentage / 100) *
        (chargeEfficiency / 100) *
        (dischargeEfficiency / 100) *
        (chargeMlf / 100) *
        (1 / (dischargeMlf / 100)) *
        100
      ).toFixed(3)}%`;
    }
    return "--";
  };

  render() {
    const { formStatus } = this.state;
    const disableEdit =
      formStatus === FORM_STATUS.SUBMITTING ||
      formStatus === FORM_STATUS.SUBMIT_SUCCESS;
    const priceElasticityModels = [
      {
        text: "Model 1",
        value: "model1",
        formula:
          "max(0,-Param_X*Additonal_Volume_Bid/Total_Market_Volume_Enabled+1)"
      },
      { text: "Model 2", value: "model2", formula: "Param_X" }
    ];
    const numberProps = {
      type: "number",
      transform: value => parseFloat(value)
    };
    return (
      <div>
        <h2 style={{ color: "rgb(20, 54, 95)" }}>Edit Assumptions</h2>
        <Form onSubmit={this.handleSubmit}>
          <div
            style={{
              height: "75vh",
              overflow: "auto",
              backgroundColor: "#fff",
              padding: "16px",
              marginBottom: "8px",
              borderRadius: "4px"
            }}
          >
            {this.renderSection("Metadata")}

            {this.renderFormItem(
              "Name",
              "name",
              "text",
              { min: 5, type: "string" },
              "Using same name as existing assumptions will replace it"
            )}
            {this.renderFormItem("Description", "description", "text", {
              type: "string",
              min: 10
            })}

            {this.renderSection("Variables")}

            {this.renderFormItem(
              "Storage Volume",
              "energy_storage_volume",
              "number",
              numberProps,
              null,
              null,
              "MWhr"
            )}
            {this.renderFormItem(
              "Cycle Cost",
              "energy_cycle_cost_dollar",
              "number",
              numberProps,
              null,
              "$",
              "/MWhr"
            )}
            {this.renderFormItem(
              "Cycle Cost Percentage",
              "energy_cycle_cost_percent",
              "number",
              {
                ...numberProps,
                min: 0,
                max: 100
              },
              null,
              null,
              "%"
            )}

            {this.renderFormItem(
              "Charge Efficiency",
              "energy_charge_efficiency",
              "number",
              {
                ...numberProps,
                min: 50,
                max: 100
              },
              null,
              null,
              "%"
            )}
            {this.renderFormItem(
              "Discharge Efficiency",
              "energy_discharge_efficiency",
              "number",
              {
                ...numberProps,
                min: 50,
                max: 100
              },
              null,
              null,
              "%"
            )}
            {this.renderFormItem(
              "Charge Marginal Loss Factor",
              "energy_charge_mlf",
              "number",
              {
                ...numberProps,
                min: 50,
                max: 150
              },
              null,
              null,
              "%"
            )}
            {this.renderFormItem(
              "Discharge Marginal Loss Factor",
              "energy_discharge_mlf",
              "number",
              {
                ...numberProps,
                min: 50,
                max: 150
              },
              null,
              null,
              "%"
            )}
            <Form.Item
              {...formItemLayout}
              style={{ marginBottom: "8px" }}
              label={"Net Efficiency"}
            >
              <span>{this.getNetEfficiency()}</span>
            </Form.Item>

            {this.renderSection("Price Elasticity")}

            <Alert
              style={{ marginBottom: "8px" }}
              message={"Model formulas"}
              description={
                <div>
                  {priceElasticityModels.map(model => (
                    <div key={model.text}>
                      <b>{model.text}: </b>
                      {model.formula}
                    </div>
                  ))}
                </div>
              }
              type={"info"}
              showIcon
            />

            {this.renderFormRadio(
              "Energy Price Elasticity Model",
              "energy_price_elasticity_model",
              priceElasticityModels
            )}
            {this.renderFormItem(
              "Energy Price Elasticity Param_X",
              "energy_price_elasticity_param_x",
              "number",
              numberProps
            )}
            {fcasServices.map(service => [
              this.renderFormRadio(
                `${service} Price Elasticity Model`,
                `${service.toLowerCase()}_price_elasticity_model`,
                priceElasticityModels
              ),
              this.renderFormItem(
                `${service} Price Elasticity Param_X`,
                `${service.toLowerCase()}_price_elasticity_param_x`,
                "number",
                numberProps
              )
            ])}

            {this.renderSection("Availability")}
            {this.renderFormItem(
              "Energy Charge Availability",
              "energy_charge_availability",
              "number",
              numberProps,
              null,
              null,
              "MW"
            )}
            {this.renderFormItem(
              "Energy Discharge Availability",
              "energy_discharge_availability",
              "number",
              numberProps,
              null,
              null,
              "MW"
            )}
            {fcasServices.map(service =>
              this.renderFormItem(
                `${service} Availability`,
                `${service.toLowerCase()}_availability`,
                "number",
                numberProps,
                null,
                null,
                "MW"
              )
            )}
            {this.renderSection("Utilisation")}

            {fcasServices.map(service =>
              this.renderFormItem(
                `${service} Utilisation`,
                `${service.toLowerCase()}_utilisation`,
                "number",
                {
                  ...numberProps,
                  min: 0,
                  max: 100
                },
                null,
                null,
                "%"
              )
            )}
          </div>

          <Form.Item {...tailFormItemLayout}>
            <Button type="primary" htmlType="submit" disabled={disableEdit}>
              Save Assumptions
            </Button>

            {formStatus === FORM_STATUS.SUBMITTING && (
              <Spin size="large">
                <Alert
                  showIcon
                  message="Saving your assumptions..."
                  type="info"
                  description="Please wait while your assumptions are being saved. This may take a few seconds."
                />
              </Spin>
            )}
            {formStatus === FORM_STATUS.SUBMIT_SUCCESS && (
              <Alert
                type="success"
                showIcon
                message="Assumptions saved successfully"
                description=""
              />
            )}
            {formStatus === FORM_STATUS.SUBMIT_ERROR && (
              <Alert
                type="error"
                showIcon
                message="Something went wrong"
                description="There was an error in saving your assumptions. Try saving again. If error persists, contact pdView."
              />
            )}
          </Form.Item>
        </Form>
      </div>
    );
  }
}

AssumptionsForm.propTypes = {
  pocAPI: PropTypes.func,
  assumptions: PropTypes.object,
  onFormSubmitSuccess: PropTypes.func
};

export default Form.create()(AssumptionsForm);
