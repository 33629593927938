import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "components";

const Logout = ({ auth }) => {
  auth.logout();
  return <Spinner message={"Logging out"} />;
};

Logout.propTypes = {
  auth: PropTypes.object.isRequired
};

export default Logout;
