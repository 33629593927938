import React from "react";
import { Alert } from "antd";

const Error = ({ style, message, description }) => {
  return (
    <Alert
      style={style}
      type={"error"}
      message={message ? message : "Error"}
      description={
        description
          ? description
          : "Something went wrong processing your request"
      }
      showIcon
    />
  );
};

export default Error;
