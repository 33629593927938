import React from "react";
import { Route, Router, Switch } from "react-router-dom";
import App from "./App";
import { history } from "utils/history";
import { Auth } from "utils/auth";
import {
  NotFound,
  Login,
  Logout,
  Callback,
  SignUp,
  Unauthorised
} from "./views";
import { AuthenticatedRoute } from "components";

const auth = new Auth();

const handleAuthentication = ({ location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication();
  }
};

export const MainRoutes = () => {
  return (
    <Router history={history}>
      <Switch>
        <Route
          path="/login"
          render={props => <Login auth={auth} {...props} />}
        />
        <Route
          path="/signup"
          render={props => <SignUp auth={auth} {...props} />}
        />
        <Route
          path="/callback"
          render={props => {
            handleAuthentication(props);
            return <Callback {...props} />;
          }}
        />
        <Route path={"/unauthorised"} component={Unauthorised} />
        <AuthenticatedRoute auth={auth} exact path="/" component={App} />
        <AuthenticatedRoute
          auth={auth}
          path="/app/:view/:solveid?"
          component={App}
        />
        <AuthenticatedRoute auth={auth} path="/logout" component={Logout} />
        <Route component={NotFound} />
      </Switch>
    </Router>
  );
};
