import React, { Component } from "react";
import PropTypes from "prop-types";

import {
  ReportView,
  ChartGeneratedBids,
  TableGeneratedBids
} from "components";

class ReportGeneratedBids extends Component {
  render() {
    const { style, data, title, loading, selectedReportView } = this.props;

    return (
      <div style={style}>
        {selectedReportView === ReportView.Chart && (
          <ChartGeneratedBids data={data} loading={loading} title={title} />
        )}
        {selectedReportView === ReportView.Table && (
          <TableGeneratedBids data={data} loading={loading} title={title} />
        )}
      </div>
    );
  }
}

ReportGeneratedBids.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  loading: PropTypes.bool,
  data: PropTypes.array,
  selectedReportView: PropTypes.string.isRequired
};

export default ReportGeneratedBids;
