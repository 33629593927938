import React from "react";
import PropTypes from "prop-types";
import { Table, TableHelper } from "components/generic";

const TableRevenueActual = ({ style, data, loading, title }) => {
  const renderCell = (record, key, style) => {
    const settDt = record.settDt;
    const value = dataSource[settDt][key];
    return TableHelper.formatCell(value, style);
  };

  const settDts = [];
  let dataSource = {};

  let revenueColumns = [];
  let utilisationRevenueColumns = [];
  let liabililiesColumns = [];

  [
    "GenEnergy",
    "LoadEnergy",
    "RaiseReg",
    "Raise5min",
    "Raise60sec",
    "Raise6sec",
    "LowerReg",
    "Lower5min",
    "Lower60sec",
    "Lower6sec"
  ].forEach(service => {
    revenueColumns.push({
      title: TableHelper.formatHeader(service),
      align: "right",
      key: `revenue-${service}`,
      width: 100,
      render: (text, record) =>
        renderCell(record, `${service.toLowerCase()}-revenue`)
    });
    if (service !== "GenEnergy" && service !== "LoadEnergy")
      //no energy utilisation revenue
      utilisationRevenueColumns.push({
        title: TableHelper.formatHeader(service),
        align: "right",
        key: `utilisation-revenue-${service}`,
        width: 100,
        render: (text, record) =>
          renderCell(record, `${service.toLowerCase()}-utilisation-revenue`)
      });
  });
  ["GenLiability", "LoadLiability"].forEach(service => {
    liabililiesColumns.push({
      title: TableHelper.formatHeader(service),
      align: "right",
      key: `revenue-${service}`,
      width: 100,
      render: (text, record) =>
        renderCell(record, `${service.toLowerCase()}-revenue`)
    });
  });
  const columns = [
    {
      title: TableHelper.formatHeader("Settlement date"),
      dataIndex: "settDt",
      key: "settDt",
      fixed: "left",
      width: 200
    },
    {
      title: TableHelper.formatHeader("Net Revenue"),
      width: 100,
      key: "net-revenue",
      fixed: "left",

      render: (text, record) =>
        renderCell(record, "net-revenue", { fontWeight: "bold" }),
      align: "right"
    },
    {
      title: TableHelper.formatHeader("Revenue"),
      key: "revenue",
      children: revenueColumns,
      width: 900
    },
    {
      title: TableHelper.formatHeader("Utilisation Revenue"),
      key: "utilisation-revenue",
      children: utilisationRevenueColumns,
      width: 800
    },
    {
      title: TableHelper.formatHeader("Contingency Liability Revenue"),
      key: "liability-revenue",
      children: liabililiesColumns
    },
    {
      title: TableHelper.formatHeader("Cycle Cost Revenue"),
      width: 100,
      key: "cycle-cost-revenue",
      render: (text, record) => renderCell(record, "cyclecost-revenue"),
      align: "right"
    }
  ];

  data.forEach(d => {
    const settDt = d.settlementdate;
    if (settDts.indexOf(settDt) < 0) settDts.push(settDt);

    const rev = `${d.service.toLowerCase()}-revenue`;
    const utilRev = `${d.service.toLowerCase()}-utilisation-revenue`;
    if (!dataSource[settDt]) dataSource[settDt] = {};
    dataSource[settDt] = {
      ...dataSource[settDt],
      [rev]: d.revenue,
      [utilRev]: d.utilisation_revenue,
      "net-revenue": (
        (dataSource[settDt]["net-revenue"]
          ? parseFloat(dataSource[settDt]["net-revenue"])
          : 0) +
        (d.revenue ? parseFloat(d.revenue) : 0) +
        (d.utilisation_revenue ? parseFloat(d.utilisation_revenue) : 0)
      ).toFixed(2)
    };
  });

  return (
    <Table
      dataSource={Object.keys(dataSource).map(ds => {
        return { key: `row-${ds}`, settDt: ds };
      })}
      columns={columns}
      loading={loading}
      title={title}
      style={style}
    />
  );
};

TableRevenueActual.propTypes = {
  style: PropTypes.object,
  data: PropTypes.array,
  title: PropTypes.string,
  loading: PropTypes.bool
};

export default TableRevenueActual;
