import React, { Component } from "react";
import PropTypes from "prop-types";
import { Layout, Menu } from "antd";
import { NavLink } from "react-router-dom";

import { Logo, Icon } from "components/generic";

class AppSider extends Component {
  state = {
    isCollapsed: true
  };

  iconStyle = {
    marginRight: "8px",
    verticalAlign: "initial"

    // color: "rgb(13, 117, 110)"
  };

  onCollapseToggle = collapsed => {
    this.setState({
      isCollapsed: collapsed
    });
  };

  render() {
    const { isCollapsed } = this.state;
    const { location } = this.props;
    const backgroundColor = "rgb(20, 54, 96)";
    const menuSelectedKeys = [location];

    return (
      <Layout.Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={this.onCollapseToggle}
        width={280}
        style={{ backgroundColor: backgroundColor }}
      >
        <div style={{ textAlign: "center" }}>
          <NavLink to={"/app/home"}>
            <Logo />
            <span>
              <h3
                style={{
                  color: "white",
                  lineHeight: "18px",
                  fontWeight: "bold"
                }}
              >
                vAdvisor POC
              </h3>
            </span>
          </NavLink>
        </div>
        <Menu
          theme={"dark"}
          mode={"inline"}
          selectedKeys={menuSelectedKeys}
          style={{
            backgroundColor: backgroundColor
          }}
        >
          <Menu.Item key={"/app/home"}>
            <NavLink to={"/app/home"}>
              <Icon
                icon={"home"}
                className={"anticon"}
                style={this.iconStyle}
              />
              <span>Home</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={"/app/assumptions"}>
            <NavLink to={"/app/assumptions"}>
              <Icon
                icon={"audit"}
                className={"anticon"}
                style={this.iconStyle}
              />
              <span>Assumptions</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={"/app/solver"}>
            <NavLink to={"/app/solver"}>
              <Icon
                icon={"dollar"}
                className={"anticon"}
                style={this.iconStyle}
              />
              <span>Solver</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={"/app/progress"}>
            <NavLink to={"/app/progress"}>
              <Icon
                icon={"pie-chart"}
                className={"anticon"}
                style={this.iconStyle}
              />
              <span>Progress</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={"/app/results"}>
            <NavLink to={"/app/results"}>
              <Icon
                icon={"area-chart"}
                className={"anticon"}
                style={this.iconStyle}
              />
              <span>Results</span>
            </NavLink>
          </Menu.Item>
          <Menu.Item key={"/contact"}>
            <a
              href="http://pdview.com.au/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              <Icon
                icon={"message"}
                className={"anticon"}
                style={this.iconStyle}
              />
              <span>Contact pdView</span>
            </a>
          </Menu.Item>
        </Menu>
        {!isCollapsed && (
          <small
            style={{
              paddingLeft: "24px",
              color: "#ddd",
              textAlign: "center",
              display: "block",
              borderTop: "1px solid  rgb(1, 27, 60)",
              marginTop: "8px",
              paddingTop: "8px"
            }}
          >
            <b>vAdvisor POC</b> <br />
            © 2019{" "}
            <a
              href="http://pdview.com.au"
              target="_blank"
              rel="noopener noreferrer"
            >
              pdView
            </a>{" "}
            Pty Ltd. All rights reserved.{" "}
          </small>
        )}
      </Layout.Sider>
    );
  }
}

AppSider.propTypes = {
  location: PropTypes.string.isRequired
};

export default AppSider;
