import React, { Component } from "react";
import { func } from "prop-types";
import { ProgressList } from "../../components";

class ProgressCP extends Component {
  state = {
    solvesListFetching: {
      loading: true,
      error: false
    },
    solvesList: []
  };

  componentDidMount() {
    this.loadSolvesList();
  }

  loadSolvesList() {
    const { pocAPI, isDeveloper } = this.props;
    this.setState(
      prevState => ({
        solvesListFetching: {
          ...prevState.solvesListFetching,
          loading: true
        }
      }),
      () =>
        pocAPI
          .get(`/v1/solver/solves?is_developer=${isDeveloper}`)
          .then(response => {
            if (response)
              this.setState(prevState => ({
                solvesList: response.data,
                solvesListFetching: {
                  ...prevState.solvesListFetching,
                  loading: false
                }
              }));
          })
          .catch(err => {
            console.log("Error fetching data", err);
            this.setState(prevState => ({
              solvesListFetching: {
                ...prevState.solvesListFetching,
                loading: false,
                error: true
              }
            }));
          })
    );
  }

  render() {
    const { solvesListFetching, solvesList } = this.state;
    return (
      <div style={{ padding: "24px" }}>
        <ProgressList
          items={solvesList}
          error={solvesListFetching.error}
          pocAPI={this.props.pocAPI}
          loading={solvesListFetching.loading}
        />
      </div>
    );
  }
}

ProgressCP.propTypes = {
  pocAPI: func.isRequired
};

export default ProgressCP;
