import React, { Component } from "react";
import { Row, Col } from "antd";
import PropTypes from "prop-types";

import { AssumptionsForm, AssumptionsList } from "components";
import { Spinner, Error } from "components/generic";

class Assumptions extends Component {
  state = {
    assumptionsListFetching: {
      loading: true,
      error: false
    },
    assumptionsList: [],
    assumptions: {},
    assumptionsFetching:{
      loading: false,
      error: false
    }
  };

  componentDidMount() {
    this.loadAssumptionsList();
  }

  loadAssumptionsList() {
    const { pocAPI } = this.props;
    const self = this;
    self.setState(prevState => ({
      assumptionsListFetching: {
        ...prevState.assumptionsListFetching,
        loading: true
      }
    }), () => pocAPI
      .get("/v1/assumptions?columns=name,description")
      .then(response => {
        if (response)
          self.setState(prevState => ({
            assumptionsList: response.data,
            assumptionsListFetching: {
              ...prevState.assumptionsListFetching,
              loading: false
            }
          }));
      })
      .catch(err => {
        console.log("Error fetching data", err);
        self.setState(prevState => ({
          assumptionsListFetching: {
            ...prevState.assumptionsListFetching,
            loading: false,
            error: true
          }
        }));
      }))

  }

  onEditAssumption = assumptionName => () => {
    const { pocAPI } = this.props;
    const self = this;
    self.setState(prevState => ({
      assumptionsFetching: {
        ...prevState.assumptionsFetching,
        loading: true
      }
    }), () =>  pocAPI
      .get(`/v1/assumptions/${assumptionName}`)
      .then(response => {
        if (response && response.data && response.data.length > 0)
          self.setState(prevState => ({
            assumptions: response.data[0],
            assumptionsFetching: {
              ...prevState.assumptionsFetching,
              loading: false
            }
          }));
      })
      .catch(err => {
        console.log("Error fetching data", err);
        self.setState({
          assumptionsFetching: {
            loading: false,
            error: true
          }
        })
      }));

  };

  render() {
    const {
      assumptionsListFetching,
      assumptionsList,
      assumptions,
      assumptionsFetching
    } = this.state;
    const { pocAPI } = this.props;
    return (
      <div style={{ padding: "24px" }}>
        <Row gutter={24}>
          <Col span={8}>
            <AssumptionsList
              style={{
                backgroundColor: "white",
                padding: "24px",
                borderRadius: "4px"
              }}
              loading={assumptionsListFetching.loading}
              error={assumptionsListFetching.error}
              items={assumptionsList}
              onEditAssumption={this.onEditAssumption}
            />
          </Col>
            <Col span={16}>
              <div style={{backgroundColor: '#dedede', padding: '16px', borderRadius: '4px', minHeight: '500px'}}>
            { assumptionsFetching.loading ?
              <Spinner message={"Loading assumptions"} style={{marginTop: '200px'}}/>:
              assumptionsFetching.error ?
                <Error message={"Error getting assumptions"}/>:
                <AssumptionsForm pocAPI={pocAPI} assumptions={assumptions} onFormSubmitSuccess={() => this.loadAssumptionsList()} />
            }
              </div>
          </Col>
        </Row>
      </div>
    );
  }
}
Assumptions.propTypes = {
  pocAPI: PropTypes.func.isRequired
};
export default Assumptions;
