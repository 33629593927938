import React from "react";
import PropTypes from "prop-types";

import { ReportContainer } from "components";
import { Chart, ChartHelper } from "components/generic";

const ChartPhysicalForecast = ({
  style,
  title,
  chartHeight,
  data,
  loading
}) => {
  let socSeriesData = [];
  let socDeltaSeriesData = [];
  data.soc.forEach((row, i) => {
    const dt = ChartHelper.formatDateTime(row.forecast_settlementdate);
    const value = parseFloat(row.soc);
    socSeriesData.push([dt, value]);

    if (i > 0) {
      //exclude first one
      const delta = value - parseFloat(data.soc[i - 1].soc);
      socDeltaSeriesData.push([dt, delta]);
    }
  });

  const services = [
    "Energy",
    "Raisereg",
    "Raise5min",
    "Raise60sec",
    "Raise6sec",
    "Lowerreg",
    "Lower5min",
    "Lower60sec",
    "Lower6sec"
  ];

  let seriesByService = {};

  data.prices.forEach(row => {
    const dt = ChartHelper.formatDateTime(row.forecast_settlementdate);
    services.forEach(service => {
      if (!seriesByService[service]) seriesByService[service] = [];

      const value = parseFloat(row[`${service.toLowerCase()}rrp`]);
      seriesByService[service].push([dt, value]);
    });
  });

  let seriesShared = [
    {
      type: "line",
      name: "State of charge",
      data: socSeriesData,
      yAxis: 0
    },
    {
      type: "column",
      name: "Change in state of charge",
      data: socDeltaSeriesData,
      yAxis: 0
    }
  ];

  let yAxis = [
    {
      title: { text: "MW(hr)" }
    },
    { title: { text: "$/MWhr" }, opposite: true }
  ];

  return (
    <div style={style}>
      {services.map(service => (
        <ReportContainer key={service}>
          <Chart
            title={`${title} - ${service}`}
            series={seriesShared.concat({
              type: "line",
              name: `Forecast ${service} RRP`,
              yAxis: 1,
              data: seriesByService[service]
            })}
            chartHeight={chartHeight}
            xAxisTitle={"Forecast settlement date"}
            yAxis={yAxis}
            loading={loading}
          />
        </ReportContainer>
      ))}
    </div>
  );
};

ChartPhysicalForecast.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  chartHeight: PropTypes.number,
  data: PropTypes.shape({
    soc: PropTypes.array,
    prices: PropTypes.array
  }),
  loading: PropTypes.bool
};

export default ChartPhysicalForecast;
