import React from "react";
import { Row, Col } from "antd";

import { Icon } from "components/generic";

const Home = () => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>
        <h1
          style={{
            fontSize: "1.8em",
            padding: "24px",
            color: "rgb(20, 54, 95)"
          }}
        >
          vAdvisor - Proof of concept <small>by pdView</small>
        </h1>
        <div
          style={{
            textAlign: "left",
            padding: "24px",
            background: "#fff",
            margin: "0 24px",
            borderRadius: "4px"
          }}
        >
          This proof of concept is the deliverable for Stage 1, POC, of the
          Contract.
          <div>
            <b>Procedure: </b>
            The deliverable of this work is to show bids, settlements (a
            breakdown of revenue and costs) and dispatch (state of charge) for
            specified historic periods in the market(s). This should include for
            each interval:
            <ul>
              <li>
                A set of representative bids for the next applicable dispatch
                interval (inclusive of all markets, generator and load units but
                not price bands)
              </li>
              <li>Cleared prices for each market</li>
              <li>Settlement results</li>
              <li>
                Plant SOC calculated for each interval for the duration of
                predispatch
              </li>
            </ul>
          </div>
          <div>
            <b>Expected result: </b>
            That the value generated by the Facility is more than 85% of the
            value calculated by the Client and if not identify the discrepancy
            to the Client’s satisfaction. That the forecast and actual
            (modelled) Facility state is within physical limits.
          </div>
          <div>
            <b>Constraints: </b>
            The following are provided as an effective rule set for producing
            the deliverable:
            <ol>
              <li>
                Bids are to be determined only by contemporary market
                information as would be the case in operation (i.e.
                Pre-dispatch). Specifically, this is not to be based on perfect
                foresight of market outcomes
              </li>
              <li>
                Settlement is to be consistent with the basic NEMDE trapezium
                and co-optimisation principals as they apply to plant bids (not
                with regard to the broader market)
              </li>
              <li>Settlement to be applied on 5-minute basis</li>
              <li>
                Dispatch to be consistent with physical constraints of plant
                (Energy/SOC, rated power, round trip efficiency), and market
                constraints (linear energy target ramp, FCAS Contingency
                registered capacity)
              </li>
              <li>Account to be taken of plant throughoutcycle cost</li>
              <li>Energy market prices to be considered non-elastic</li>
              <li>FCAS market prices to be elastic</li>
              <li>FCAS bid volume to be capped</li>
              <li>
                FCAS prices to reference VIC1 node and apply multiplier schedule
              </li>
              <li>FCAS regulation utilisation to be 25% </li>
              <li>Other assumptions supplied by the Client. </li>
            </ol>
          </div>
          <Row gutter={48}>
            <Col span={6}>
              <h1>
                <Icon icon={"audit"} /> Assumptions
              </h1>
              <p>
                The assumptions page allows the user to save any set of
                assumptions that can be used as inputs into the solve cycle. The
                user may also inspect the assumptions or edit them for any given
                set
              </p>
            </Col>
            <Col span={6}>
              <h1>
                <Icon icon={"dollar"} /> Solver
              </h1>
              <p>
                The Solver page allows the user to select a given set of
                assumptions and to select a date range. The no of scenarios is
                set to one and cannot be changed. A scenario referes to a price
                scenario and the app requires only the current predispatch price
                to be selected.
              </p>
            </Col>
            <Col span={6}>
              <h1>
                <Icon icon={"pie-chart"} /> Progress
              </h1>
              <p>
                The Progress page shows the progress of each solve by
                calculating the expected number of intervals and matching
                against solved intervals, calculated based on the passed date
                interval for the solve.
              </p>
            </Col>
            <Col span={6}>
              <h1>
                <Icon icon={"area-chart"} /> Results
              </h1>
              <p>
                The results page allows the user to inspect the following either
                in graph mode or table mode.{" "}
              </p>
              <ol>
                <li>Aggregated actual daily revenue breakdown</li>
                <li>Five minute actual revenue breakdown</li>
                <li>Five minute actual physical dispatch and SOC</li>
                <li>
                  Five minute actual bids. Note that only the next immediate bid
                  is published.
                </li>
                <li>
                  Forecast profile of SOC published every five minutes - only
                  the forecast SOC shows a complete profile for each 5 minute
                  solve.
                </li>
              </ol>
            </Col>
          </Row>
          <h4>
            Any issues, please{" "}
            <a
              href="http://pdview.com.au/contact"
              target="_blank"
              rel="noopener noreferrer"
            >
              <span>contact pdView</span>
            </a>.
          </h4>
        </div>
      </div>
    </div>
  );
};

export default Home;
