import React from "react";
import PropTypes from "prop-types";
import { Spinner } from "components";

const Login = ({ auth }) => {
  auth.login();
  return <Spinner message={"Logging in"} />;
};

Login.propTypes = {
  auth: PropTypes.object.isRequired
};

export default Login;
