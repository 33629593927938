import React, { Component } from "react";
import { NavLink, Redirect } from "react-router-dom";
import { Layout } from "antd";

import "./App.css";
import { AppSider } from "components";
import { Home, Assumptions, Results, Solver, Progress } from "views";
import axios from "axios";

class App extends Component {
  state = {
    profile: {
      loading: true,
      error: false,
      errorMsg: "",
      data: null
    },
    pocAPI: {},
    isDeveloper: false
  };

  componentWillMount() {
    const pocAPI = axios.create({
      baseURL: "https://api.bidpoc.pdview.com.au"
      // headers: {
      //   Authorization: `Bearer ${idToken}`
      // }
    });

    const isDeveloper = localStorage.getItem("is_developer") === "true";

    this.setState({ pocAPI: pocAPI, isDeveloper: isDeveloper });
  }

  renderView = view => {
    const { pocAPI, isDeveloper } = this.state;
    const { match: { params: { solveid } } } = this.props;

    if (!view) {
      return <Redirect to={"/app/home"} />;
    }
    switch (view.toLowerCase()) {
      case "home":
        localStorage.setItem("last_path", "/app/home");
        return <Home />;
      case "assumptions":
        localStorage.setItem("last_path", "/app/assumptions");
        return <Assumptions pocAPI={pocAPI} />;
      case "solver":
        localStorage.setItem("last_path", "/app/solver");
        return <Solver pocAPI={pocAPI} isDeveloper={isDeveloper} />;
      case "progress":
        localStorage.setItem("last_path", "/app/progress");
        return <Progress pocAPI={pocAPI} />;
      case "results":
        localStorage.setItem("last_path", "/app/results");
        return (
          <Results
            pocAPI={pocAPI}
            solveId={solveid}
            isDeveloper={isDeveloper}
          />
        );

      default:
        return (
          <div>
            View not found. Please check the url. Go to{" "}
            <NavLink to={"/app/home"}>Home</NavLink>
          </div>
        );
    }
  };

  render() {
    const { history, match } = this.props;
    const view = match.params.view;
    console.log("APP", this.props);
    return (
      <div>
        <Layout style={{ minHeight: "100vh" }}>
          <AppSider location={history.location.pathname} />
          <Layout>
            <Layout.Content>{this.renderView(view)}</Layout.Content>
          </Layout>
        </Layout>
      </div>
    );
  }
}

export default App;
