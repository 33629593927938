import React from "react";
import PropTypes from "prop-types";
import { List, Button, Descriptions } from "antd";
import { Icon, Error } from "../generic";

const AssumptionsList = ({
  style,
  loading,
  error,
  items,
  onEditAssumption
}) => {
  return (
    <div style={style}>
      <h2 style={{ color: "rgb(20, 54, 95)" }}>Assumptions List</h2>
      {error && (
        <Error
          message={"Failed to load Assumptions List"}
          description={
            "Something went wrong while loading assumptions list. Try refreshing page, if error persists, contact pdView."
          }
        />
      )}
      <List
        itemLayout={"horizontal"}
        loading={loading}
        dataSource={items}
        size="small"
        renderItem={item => (
          <List.Item key={item.name}>
            <List.Item.Meta
              description={
                <Descriptions title={item.name}>
                  <Descriptions.Item label={"Description"}>
                    {item["description"]}
                  </Descriptions.Item>
                  {/*{Object.keys(item).map(k => (*/}
                  {/*  <Descriptions.Item label={k}>{item[k]}</Descriptions.Item>*/}
                  {/*))}*/}
                </Descriptions>
              }
            />
            <Button
              type="primary"
              shape="circle"
              onClick={onEditAssumption(item.name)}
            >
              <Icon icon={"edit"} />
            </Button>
          </List.Item>
        )}
      />
    </div>
  );
};

AssumptionsList.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string
    })
  ),
  onEditAssumption: PropTypes.func.isRequired
};

export default AssumptionsList;
