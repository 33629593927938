import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ReportView,
  TableRevenueActual,
  ChartRevenueActual,
  ReportContainer
} from "components";

class ReportRevenueActual extends Component {
  state = {
    chartHeight: 600
  }

  componentDidMount() {
    this.resizeChart();
  }

  resizeChart(){
    const height = window.innerHeight - 200;
    this.setState({
      chartHeight: height
    })
  }

  render() {
    const { style, title, data, loading, selectedReportView } = this.props;
    const { chartHeight } = this.state;
    return (
      <ReportContainer style={style}>
        <div>
          {selectedReportView === ReportView.Table && (
            <TableRevenueActual data={data} loading={loading} title={title} />
          )}
          {selectedReportView === ReportView.Chart && (
            <ChartRevenueActual data={data} loading={loading} title={title} chartHeight={chartHeight} />
          )}
        </div>
      </ReportContainer>
    );
  }
}

ReportRevenueActual.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  data: PropTypes.array,
  loading: PropTypes.bool,
  selectedReportView: PropTypes.string
};

export default ReportRevenueActual;
