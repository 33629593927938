import React, { Component } from "react";
import PropTypes from "prop-types";
// import moment from "moment";

import { Chart } from "components/generic";

// const MillisecondsOffset = 10 * 60 * 60 * 1000; //utc + 10 hours

class ChartRevenueActualDrilldown extends Component {
  state = {
    drillData: {}
  };

  static formatDateTime(dtRaw) {
    return new Date(dtRaw).getTime(); //return datetime as required by highcharts
  }

  onClickDrilldownEvent = e => {
    return;
    // const { getDataForDay } = this.props;
    // const dt = e.category;
    // const dtFull = moment(dt).format("YYYY-MM-DD");
    // console.log("DRILL", e, dt, dtFull);
    // const id = `dt-${dt}`;
    // if (!this.state.drillData[id])
    //   this.setState(
    //     prevState => ({
    //       drillData: {
    //         ...prevState.drillData,
    //         [id]: {}
    //       }
    //     }),
    //     () => getDataForDay(dtFull)
    //   );
    // //this.getDataForDay(dtFull);
  };

  getSeriesData = (data, allowDrillDown, id) => {
    let dataByService = {};
    let netRevenueByDt = {};
    data.forEach(d => {
      const serviceRev = `${d.service}-Revenue`;
      const serviceUtil = `${d.service}-Utilisation Revenue`;
      if (!dataByService[serviceRev]) dataByService[serviceRev] = [];
      if (!dataByService[serviceUtil]) dataByService[serviceUtil] = [];

      const dt = ChartRevenueActualDrilldown.formatDateTime(d.settlementdate);
      const rev = parseFloat(d.revenue);
      const utilRev = parseFloat(d.utilisation_revenue);
      let netRev = 0;
      if (!netRevenueByDt[dt]) netRevenueByDt[dt] = 0;

      //add revenue
      if (d.revenue) {
        dataByService[serviceRev].push({
          x: dt,
          y: rev,
          drilldown: allowDrillDown ? serviceRev : "" //`dt-${dt}`
        });
        netRev += rev;
      }
      //add util revenue
      if (d.utilisation_revenue) {
        dataByService[serviceUtil].push({
          x: dt,
          y: utilRev,
          drilldown: allowDrillDown ? serviceUtil : ""
        });
        netRev += utilRev;
      }

      //add net revenue t
      netRevenueByDt[dt] += netRev;
    });

    //
    let series = [];
    const services = Object.keys(dataByService);

    // const dotPattern = {
    //   path: "M 0 0 L 10 10 M 9 - 1 L 11 1 M - 1 9 L 1 11",
    //   width: 10,
    //   height: 10
    // };

    services.forEach((service, i) => {
      //let props = {};
      // if (service.indexOf("Utilisation") < 0)
      //   props = { color: { pattern: dotPattern } };
      series.push({
        type: "column",
        id: service,
        stacking: "normal",
        // events: { click: (e, f) => console.log("series click", e, f) },
        name: service,
        data: dataByService[service]
        //  ...props
      });
    });

    //add net revenue line
    series.push({
      id: "Net revenue",
      name: "Net revenue",
      type: "area",
      color: "rgba(84, 173, 28, 0.2)",
      dataLabels: {
        enabled: true
      },
      negativeColor: "rgba(197, 19, 19, 0.2)",
      // events: { click: (e, f) => console.log("series click", e, f) },
      data: Object.keys(netRevenueByDt).map(dt => {
        return {
          x: parseInt(dt),
          y: parseFloat(netRevenueByDt[dt]),
          drilldown: allowDrillDown ? "Net revenue" : "" //`dt-${dt}`
        };
      })
    });
    return series;
  };

  render() {
    const {
      data,
      title,
      chartHeight,
      drilldownData,
      drilldownDataLoading
    } = this.props;
    console.log("CHART REVENUE PROPS", this.props);

    const series = this.getSeriesData(data, true, "aaa");

    let drilldown = {};
    if (drilldownData && drilldownData.length > 0) {
      drilldown = {
        allowPointDrilldown: false,
        series: this.getSeriesData(drilldownData, false, "ddd")
      };
    }
    return (
      <div>
        {" "}
        <Chart
          title={title}
          series={series}
          chartHeight={chartHeight}
          yAxisTitle={"Revenue ($)"}
          xAxisTitle={"Settlement date"}
          drilldown={drilldown}
          events={{
            drilldown: this.onClickDrilldownEvent
          }}
          // plotOptions={{ series: { dataLabels: { enabled: true } } }}
        />
        {drilldownDataLoading && <div>loading...</div>}
      </div>
    );
  }
}

ChartRevenueActualDrilldown.propTypes = {
  data: PropTypes.array,
  drilldownData: PropTypes.object,
  drilldownDataLoading: PropTypes.bool,
  title: PropTypes.string,
  chartHeight: PropTypes.number,
  getDataForDay: PropTypes.func
};

export default ChartRevenueActualDrilldown;
