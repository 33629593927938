import React, { Component } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
// import { history } from "utils/history";
import { SolveForm, SolvesList } from "components";

class Solver extends Component {
  state = {
    solvesListFetching: {
      loading: true,
      error: false
    },
    solvesList: []
  };

  componentDidMount() {
    this.loadSolvesList();
  }

  loadSolvesList() {
    const { pocAPI, isDeveloper } = this.props;
    this.setState(
      prevState => ({
        solvesListFetching: {
          ...prevState.solvesListFetching,
          loading: true
        }
      }),
      () =>
        pocAPI
          .get(`/v1/solver/solves?is_developer=${isDeveloper}`)
          .then(response => {
            if (response)
              this.setState(prevState => ({
                solvesList: response.data,
                solvesListFetching: {
                  ...prevState.solvesListFetching,
                  loading: false
                }
              }));
          })
          .catch(err => {
            console.log("Error fetching data", err);
            this.setState(prevState => ({
              solvesListFetching: {
                ...prevState.solvesListFetching,
                loading: false,
                error: true
              }
            }));
          })
    );
  }

  onNewSolve = () => {
    this.loadSolvesList();
  };

  // onViewResults = solveId => () => {
  //   history.push(`/app/results/${solveId}`);
  // };

  render() {
    const { solvesListFetching, solvesList } = this.state;
    const { pocAPI, isDeveloper } = this.props;
    return (
      <div style={{ padding: "24px" }}>
        <Row gutter={24}>
          <Col span={10} style={{ textAlign: "left" }}>
            <h2 style={{ color: "rgb(20, 54, 95)" }}>New Solve Run</h2>
            <SolveForm
              pocAPI={pocAPI}
              onNewSolve={this.onNewSolve}
              isDeveloper={isDeveloper}
            />
          </Col>
          <Col span={14}>
            <SolvesList
              title={"Solve runs history"}
              style={{
                backgroundColor: "white",
                padding: "24px",
                borderRadius: "4px"
              }}
              error={solvesListFetching.error}
              loading={solvesListFetching.loading}
              items={solvesList}
              onItemClick={solveId =>
                console.log("Doing nothing on solve item click :)")
              }
            />
          </Col>
        </Row>
      </div>
    );
  }
}

Solver.propTypes = {
  pocAPI: PropTypes.func.isRequired,
  isDeveloper: PropTypes.bool
};
export default Solver;
