import React from "react";
import PropTypes from "prop-types";

import { ReportContainer } from "components";
import { Table, TableHelper } from "components/generic";

const TablePhysicalForecast = ({ style, loading, title, data }) => {
  const renderCell = (record, key) => {
    const forecastSettDt = record.forecastSettDt;
    const value = dataSource[forecastSettDt][key];
    return TableHelper.formatCell(value);
  };

  let dataSource = {};
  data.soc.forEach((row, i) => {
    const forecastSettDt = row.forecast_settlementdate;
    const soc = row.soc;
    let deltaSoc = null;
    if (i > 0) deltaSoc = parseFloat(soc) - parseFloat(data.soc[i - 1].soc);

    if (!dataSource[forecastSettDt]) dataSource[forecastSettDt] = {};
    dataSource[forecastSettDt] = {
      ...dataSource[forecastSettDt],
      soc: soc,
      delta_soc: deltaSoc
    };
  });

  const services = [
    "Energy",
    "RaiseReg",
    "Raise5min",
    "Raise60sec",
    "Raise6sec",
    "LowerReg",
    "Lower5min",
    "Lower60sec",
    "Lower6sec"
  ];

  data.prices.forEach(row => {
    const forecastSettDt = row.forecast_settlementdate;
    if (!dataSource[forecastSettDt]) dataSource[forecastSettDt] = {};

    services.forEach(service => {
      const key = `${service.toLowerCase()}rrp`;
      dataSource[forecastSettDt] = {
        ...dataSource[forecastSettDt],
        [key]: row[key]
      };
    });
  });

  let forecastRRPCols = [];
  services.forEach(service => {
    forecastRRPCols.push({
      title: TableHelper.formatHeader(service),
      align: "right",
      key: `${service.toLowerCase()}rrp`,
      width: 100,
      render: (text, record) =>
        renderCell(record, `${service.toLowerCase()}rrp`)
    });
  });

  let columns = [
    {
      title: TableHelper.formatHeader("Forecast settlement date"),
      dataIndex: "forecastSettDt",
      key: "forecastSettDt",
      fixed: "left",
      width: 200
    },
    {
      title: TableHelper.formatHeader("State of charge"),
      key: "soc",
      width: 100,
      align: "right",
      render: (text, record) => renderCell(record, "soc")
    },
    {
      title: TableHelper.formatHeader("Change in state of charge"),
      key: "delta_soc",
      width: 100,
      align: "right",
      render: (text, record) => renderCell(record, "delta_soc")
    },
    {
      title: TableHelper.formatHeader("Forecast RRP"),
      key: "forecast_rrp",
      children: forecastRRPCols
    }
  ];

  return (
    <ReportContainer style={style}>
      <Table
        loading={loading}
        title={title}
        dataSource={Object.keys(dataSource).map(ds => {
          return { key: `row-${ds}`, forecastSettDt: ds };
        })}
        style={style}
        columns={columns}
      />
    </ReportContainer>
  );
};

TablePhysicalForecast.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    soc: PropTypes.array,
    prices: PropTypes.array
  }),
  title: PropTypes.string
};

export default TablePhysicalForecast;
