import React from "react";
import PropTypes from "prop-types";

import { Table as AntdTable } from "antd";

const Table = ({ style, title, dataSource, loading, columns }) => {
  return (
    <div style={style}>
      <h3
        style={{
          textAlign: "center",
          fontSize: "18px",
          color: "#333",
          marginTop: "5px"
        }}
      >
        {title}
      </h3>
      <AntdTable
        style={{ background: "white"}}
        size={"small"}
        bordered={true}
        pagination={{ pageSize: 12, hideOnSinglePage: true }}
        dataSource={dataSource}
        columns={columns}
        scroll={{ x: "max-content", y: 800 }}
        loading={loading}

      />
    </div>
  );
};

Table.propTypes = {
  style: PropTypes.object,
  dataSource: PropTypes.array,
  columns: PropTypes.array,
  loading: PropTypes.bool,
  title: PropTypes.string
};

export default Table;
