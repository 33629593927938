import React from "react";
import PropTypes from "prop-types";

import { Layout } from "antd";

const SiderLeft = ({ style, width, children, onCollapse, isCollapsed }) => {
  return (
    <Layout.Sider
      collapsible
      width={width}
      collapsedWidth={0}
      style={{
        height: "100vh",
        // overflowX: "hidden",
        // overflowY: "scroll",
        backgroundColor: "#fff",
        ...style
      }}
      collapsed={isCollapsed}
      onCollapse={onCollapse}
    >
      {children}
    </Layout.Sider>
  );
};

SiderLeft.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any.isRequired,
  onCollapse: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  width: PropTypes.number
};

export default SiderLeft;
