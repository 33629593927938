import React from "react";
import PropTypes from "prop-types";
import { Table, TableHelper } from "components/generic";

const TablePhysicalActual = ({ style, loading, title, data }) => {
  const renderCell = (record, key, style) => {
    const settDt = record.settDt;
    const value = dataSource[settDt][key];
    return TableHelper.formatCell(value, style);
  };

  //parse soc
  let dataSource = {};
  data.soc.forEach(row => {
    const settDt = row.settlementdate;
    const soc = row.soc;
    if (!dataSource[settDt]) dataSource[settDt] = {};

    dataSource[settDt] = {
      ...dataSource[settDt],
      soc: soc
    };
  });

  //parse services data
  data.services.forEach(row => {
    const settDt = row.settlementdate;
    const service = row.service;
    const revenuePrice = row.revenue_price;
    const revenueMW = row.revenue_mw;
    const utilisationRevenueMW = row.utilisation_revenue_mw;
    if (!dataSource[settDt]) dataSource[settDt] = {};

    dataSource[settDt] = {
      ...dataSource[settDt],
      [`${service.toLowerCase()}-revenue-price`]: revenuePrice,
      [`${service.toLowerCase()}-revenue-mw`]: revenueMW,
      [`${service.toLowerCase()}-utilisation-revenue-mw`]: utilisationRevenueMW
        ? -1 * parseFloat(utilisationRevenueMW)
        : ""
    };
  });

  let revenuePriceColumns = [];
  let revenueMWColumns = [];
  let utilisationRevenueMWColumns = [];

  [
    "genEnergy",
    "loadEnergy",
    "raisereg",
    "raise5min",
    "raise60sec",
    "raise6sec",
    "lowerreg",
    "lower5min",
    "lower60sec",
    "lower6sec"
  ].forEach(service => {
    revenuePriceColumns.push({
      title: TableHelper.formatHeader(service),
      align: "right",
      key: `${service}-revenue-price`,
      width: 100,
      render: (text, record) =>
        renderCell(record, `${service.toLowerCase()}-revenue-price`)
    });
    revenueMWColumns.push({
      title: TableHelper.formatHeader(service),
      align: "right",
      key: `${service}-revenue-mw`,
      width: 100,
      render: (text, record) =>
        renderCell(record, `${service.toLowerCase()}-revenue-mw`)
    });
    if (service !== "Energy")
      //no energy utilisation revenue
      utilisationRevenueMWColumns.push({
        title: TableHelper.formatHeader(service),
        align: "right",
        key: `${service}-utilisation-revenue-mw`,
        width: 100,
        render: (text, record) =>
          renderCell(record, `${service.toLowerCase()}-utilisation-revenue-mw`)
      });
  });

  const columns = [
    {
      title: TableHelper.formatHeader("Settlement date"),
      dataIndex: "settDt",
      key: "settDt",
      fixed: "left",
      width: 200
    },
    {
      title: TableHelper.formatHeader("State of charge"),
      key: "soc",
      width: 100,
      align: "right",
      render: (text, record) => renderCell(record, "soc")
    },
    {
      title: TableHelper.formatHeader("MW"),
      key: "revenue-mw",
      children: revenueMWColumns
    },
    {
      title: TableHelper.formatHeader("Utilisation MW"),
      key: "utilisation-mw",
      children: utilisationRevenueMWColumns
    },
    {
      title: TableHelper.formatHeader("Dispatch Price"),
      key: "revenue-price",
      children: revenuePriceColumns
    }
  ];

  return (
    <Table
      dataSource={Object.keys(dataSource).map(ds => {
        return { key: `row-${ds}`, settDt: ds };
      })}
      loading={loading}
      title={title}
      columns={columns}
      style={style}
    />
  );
};

TablePhysicalActual.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.shape({
    soc: PropTypes.array,
    services: PropTypes.array
  }),
  title: PropTypes.string
};

export default TablePhysicalActual;
