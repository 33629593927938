import React from "react";
import PropTypes from "prop-types";
import {
  ReportView,
  TablePhysicalForecast,
  ChartPhysicalForecast
} from "components";

const ReportPhysicalForecast = ({
  style,
  selectedReportView,
  title,
  socData,
  pricesData,
  loading
}) => (
  <div style={style}>
    {selectedReportView === ReportView.Table && (
      <TablePhysicalForecast
        title={title}
        data={{ soc: socData, prices: pricesData }}
        loading={loading}
      />
    )}
    {selectedReportView === ReportView.Chart && (
      <ChartPhysicalForecast
        data={{ soc: socData, prices: pricesData }}
        loading={loading}
        title={title}
      />
    )}
  </div>
);

ReportPhysicalForecast.propTypes = {
  socData: PropTypes.array,
  pricesData: PropTypes.array,
  loading: PropTypes.bool,
  style: PropTypes.object,
  selectedReportView: PropTypes.string.isRequired
};

export default ReportPhysicalForecast;
