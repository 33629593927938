import React, { Component } from "react";
import PropTypes from "prop-types";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
// import HighchartsDrilldown from "highcharts/modules/drilldown";
// import HighchartsPattern from "highcharts/modules/pattern-fill";
import HighchartsExporting from "highcharts/modules/exporting";
//import HighchartsExportData from "highcharts/modules/export-data";
HighchartsExporting(Highcharts);
//HighchartsExportData(Highcharts);

// HighchartsDrilldown(Highcharts);
// HighchartsPattern(Highcharts);
Highcharts.setOptions({ lang: { loading: "Loading data..." } });
class Chart extends Component {
  state = {
    chart: null
  };

  afterChartCreated = chart => {
    this.setState({ chart: chart }, () => {
      if (this.props.loading) chart.showLoading();
    });
  };

  componentWillReceiveProps(nextProps, nextContext) {
    if (nextProps.loading) {
      if (this.state.chart) this.state.chart.showLoading();
    } else {
      if (this.state.chart) this.state.chart.hideLoading();
    }
  }

  render() {
    const {
      title,
      series,
      chartHeight,
      xAxisTitle,
      yAxisTitle,
      yAxis,
      plotOptions,
      legend
    } = this.props;

    const options = {
      exporting: {
        enabled: true
      },
      chart: {
        height: chartHeight,
        zoomType: "xy"
      },
      title: {
        text: title
      },
      series: series,
      xAxis: [
        {
          type: "datetime",
          title: {
            text: xAxisTitle
          }
        }
      ],
      yAxis: yAxis
        ? yAxis
        : [
            {
              title: {
                text: yAxisTitle
              }
            }
          ],
      legend: {
        ...legend
      },
      plotOptions: { ...plotOptions },
      tooltip: {
        shared: true
      }
    };
    return (
      <div>
        <HighchartsReact
          highcharts={Highcharts}
          options={options}
          callback={this.afterChartCreated}

          //allowChartUpdate={false}
        />
      </div>
    );
  }
}

Chart.propTypes = {
  series: PropTypes.array,
  title: PropTypes.string,
  chartHeight: PropTypes.number,
  yAxisTitle: PropTypes.string,
  xAxisTitle: PropTypes.string,
  yAxis: PropTypes.array,
  plotOptions: PropTypes.object,
  legend: PropTypes.object
  // events: PropTypes.object
  // drilldown: PropTypes.object
};

export default Chart;
