import React from "react";
import { NavLink } from "react-router-dom";
import { Icon } from "../../components/generic/icon";

const Unauthorised = () => {
  return (
    <div style={{ textAlign: "center", paddingTop: "16px" }}>
      <Icon icon={"lock"} style={{ fontSize: "10em", padding: "24px" }} />

      <h1>Access denied</h1>
      <p>
        Please try <NavLink to={"/login"}>login</NavLink> again. If error
        persists,{" "}
        <a
          href="http://pdview.com.au/contact"
          target="_blank"
          rel="noopener noreferrer"
        >
          <span>contact pdView</span>
        </a>
      </p>
    </div>
  );
};

export default Unauthorised;
