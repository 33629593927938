import React, { Component } from "react";
import PropTypes from "prop-types";

import { SiderLeft } from "components/generic";
import { SolvesList } from "components";

class SiderSolveResults extends Component {
  state = {
    isCollapsed: false
  };

  onCollapse = collapsed => {
    this.setState({ isCollapsed: collapsed });
  };

  onItemClick = solveId => {
    this.props.onChangeSolveId(solveId);
  };

  render() {
    const { isCollapsed } = this.state;
    const { loading, error, selectedSolveId, data } = this.props;
    return (
      <div>
        <SiderLeft
          onCollapse={this.onCollapse}
          isCollapsed={isCollapsed}
          width={400}
        >
          <SolvesList
            style={{
              padding: "16px 0",
              height: "100vh",
              overflow: "auto"
            }}
            title={"Solve runs results"}
            loading={loading}
            items={data}
            selectedItem={selectedSolveId}
            error={error}
            onItemClick={this.onItemClick}
            nColumns={1}
          />
        </SiderLeft>
      </div>
    );
  }
}

SiderSolveResults.propTypes = {
  style: PropTypes.object,
  data: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  selectedSolveId: PropTypes.string,
  onChangeSolveId: PropTypes.func
};

export default SiderSolveResults;
