import React from "react";
import { Link } from "react-router-dom";
import { Button } from "antd";
import { Icon } from "components";

const NotFound = () => {
  return (
    <div style={{ textAlign: "center", paddingTop: "16px" }}>
      <Icon icon={"frown"} style={{ fontSize: "10em", padding: "24px" }} />
      <h1>404 - Page Not Found</h1>
      <Button type="primary">
        <Link to="/app/home">Return to home</Link>
      </Button>
    </div>
  );
};

export default NotFound;
