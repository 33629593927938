import React from "react";
import PropTypes from "prop-types";

import { ReportView } from "./ReportView";

import { ButtonGroupSingleSelection } from "components/generic";

const ReportViewOptions = [
  { text: "Table", value: ReportView.Table, icon: "table" },
  { text: "Chart", value: ReportView.Chart, icon: "bar-chart" }
];

const ReportViewButtonGroup = ({ selectedValue, onChange, style }) => {
  return (
    <div style={style}>
      <ButtonGroupSingleSelection
        options={ReportViewOptions}
        selectedValue={selectedValue}
        onChange={onChange}
      />
    </div>
  );
};

ReportViewButtonGroup.propTypes = {
  selectedValue: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  style: PropTypes.object
};

export default ReportViewButtonGroup;
