import React from "react";
import PropTypes from "prop-types";
import commaNumber from "comma-number";
import { ReportContainer } from "../report_container";
import { Chart, ChartHelper } from "components/generic";

const colors = [
  "#367800",
  "#578833",
  "#759758",
  "#90a77c",
  "#abb7a1",
  "#c6c6c6",
  "#b7b8b7",
  "#a9aaa9",
  "#9a9b9a",
  "#8c8e8c",
  "#7e807e"
];

const ChartGeneratedBids = ({ style, title, chartHeight, data, loading }) => {
  let seriesByServices = {};

  data.forEach(row => {
    const dt = ChartHelper.formatDateTime(row.settlementdate);
    const service = row.service.toLowerCase();
    const bidPrice = row.bid_price;
    const bidVolume = row.bid_volume ? parseFloat(row.bid_volume) : 0; //0 if not specified?

    if (!seriesByServices[service]) seriesByServices[service] = {};

    if (!seriesByServices[service][bidPrice])
      seriesByServices[service][bidPrice] = [];
    seriesByServices[service][bidPrice].push([dt, bidVolume]);
  });

  //const services = Object.keys(seriesByServices);
  const services = [
    "GenEnergy",
    "LoadEnergy",
    "Raisereg",
    "Raise5min",
    "Raise60sec",
    "Raise6sec",
    "Lowerreg",
    "Lower5min",
    "Lower60sec",
    "Lower6sec"
  ];

  const getSeries = service => {
    if (seriesByServices[service]) {
      const bidPrices = Object.keys(seriesByServices[service]);
      if (service === "loadenergy") {
        return bidPrices.map((bidPrice, i) => {
          return {
            type: "column",
            name: commaNumber(parseFloat(bidPrice).toFixed(0)),
            data: seriesByServices[service][bidPrice],
            stacking: "normal",
            color: colors[bidPrices.length - 1 - i]
          };
        });
      } else
        return bidPrices.reverse().map((bidPrice, i) => {
          return {
            type: "column",
            name: commaNumber(parseFloat(bidPrice).toFixed(0)),
            data: seriesByServices[service][bidPrice],
            stacking: "normal",
            color: colors[i]
          };
        });
    }
    return null;
  };

  return (
    <div style={style}>
      {services.map(service => (
        <ReportContainer key={service}>
          <Chart
            title={`${title} - ${service}`}
            series={getSeries(service.toLowerCase())}
            legend={{ reversed: service.toLowerCase() !== "loadenergy" }}
            chartHeight={chartHeight}
            xAxisTitle={"Settlement date"}
            yAxisTitle={"MW"}
            loading={loading}
            plotOptions={{
              series: {
                pointPadding: 0,
                groupPadding: 0,
                borderWidth: 0,
                shadow: false
              }
            }}
          />
        </ReportContainer>
      ))}
    </div>
  );
};

ChartGeneratedBids.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  chartHeight: PropTypes.number,
  data: PropTypes.array,
  loading: PropTypes.bool
};

export default ChartGeneratedBids;
