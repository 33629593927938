import React from "react";
import PropTypes from "prop-types";
import { Chart, ChartHelper } from "components/generic";

const ChartPhysicalActual = ({ style, title, chartHeight, data, loading }) => {
  let seriesByService = {};

  //calc state of charge series
  let socSeriesData = [];
  data.soc.forEach(row => {
    const dt = ChartHelper.formatDateTime(row.settlementdate);
    const value = parseFloat(row.soc);
    socSeriesData.push([dt, value]);
  });

  const socSeries = {
    type: "line",
    name: "State of charge",
    data: socSeriesData,
    dashStyle: "dash",
    yAxis: 0
  };

  //calc service series data by each service
  let revenuePriceSeriesDataByService = {};
  let revenueMWSeriesDataByService = {};
  let utilisationRevenueMWSeriesDataByService = {};
  data.services.forEach(row => {
    const dt = ChartHelper.formatDateTime(row.settlementdate);
    const service = row.service;
    const revenuePrice = row.revenue_price
      ? parseFloat(row.revenue_price)
      : null;
    const revenueMW = row.revenue_mw ? parseFloat(row.revenue_mw) : null;
    const utilisationRevenueMW = row.utilisation_revenue_mw
      ? -1 * parseFloat(row.utilisation_revenue_mw)
      : null;
    if (!revenuePriceSeriesDataByService[service])
      revenuePriceSeriesDataByService[service] = [];
    revenuePriceSeriesDataByService[service].push([dt, revenuePrice]);

    if (!revenueMWSeriesDataByService[service])
      revenueMWSeriesDataByService[service] = [];
    revenueMWSeriesDataByService[service].push([dt, revenueMW]);

    if (!utilisationRevenueMWSeriesDataByService[service])
      utilisationRevenueMWSeriesDataByService[service] = [];
    utilisationRevenueMWSeriesDataByService[service].push([
      dt,
      utilisationRevenueMW
    ]);
  });

  //calculate series for each service chart
  const services = [
    "genEnergy",
    "loadEnergy",
    "raisereg",
    "raise5min",
    "raise60sec",
    "raise6sec",
    "lowerreg",
    "lower5min",
    "lower60sec",
    "lower6sec"
  ];

  services.forEach(service => {
    let series = [];

    // add soc series
    series.push(socSeries);

    //add revenue mw
    series.push({
      type: "column",
      name: "MW",
      stacking: "normal",
      data: revenueMWSeriesDataByService[service],
      yAxis: 0
    });

    //add utilisation revenue mw
    series.push({
      type: "column",
      name: "Utilisation MW",
      stacking: "normal",
      data: utilisationRevenueMWSeriesDataByService[service],
      yAxis: 0
    });

    //add revenue price
    series.push({
      type: "line",
      name: "Dispatch Price",
      data: revenuePriceSeriesDataByService[service],
      yAxis: 1
    });

    seriesByService[service] = series;
  });

  const yAxis = [
    { title: { text: "MW" } },
    { title: { text: "$/MWhr" }, opposite: true }
  ];

  return (
    <div style={style}>
      {services.map(service => (
        <div key={service} style={{ borderBottom: "3px dashed #999" }}>
          <Chart
            title={`${title} - ${service}`}
            series={seriesByService[service]}
            chartHeight={chartHeight}
            xAxisTitle={"Settlement date"}
            yAxis={yAxis}
            loading={loading}
          />
        </div>
      ))}
    </div>
  );
};

ChartPhysicalActual.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  chartHeight: PropTypes.number,
  data: PropTypes.shape({
    soc: PropTypes.array,
    services: PropTypes.array
  }),
  loading: PropTypes.bool
};
export default ChartPhysicalActual;
