import React from "react";

const Logo = () => {
  return (
    <img
      src={"/images/pdView-icon-small.png"}
      alt={"logo"}
      style={{ maxWidth: "54px" }}
    />
  );
};

export default Logo;
