import React from "react";
import PropTypes from "prop-types";

import { Spinner } from "components";

const SignUp = ({ auth }) => {
  auth.signup();
  return <Spinner message={"Redirecting to sign up page"} />;
};

SignUp.propTypes = {
  auth: PropTypes.object.isRequired
};

export default SignUp;
