import React from "react";
import PropTypes from "prop-types";

const ReportContainer = ({ style, children }) => (
  <div
    style={{
      backgroundColor: "#fff",
      padding: "16px",
      borderRadius: "4px",
      margin: "16px 0px",
      ...style
    }}
  >
    {children}
  </div>
);

ReportContainer.propTypes = {
  style: PropTypes.object,
  children: PropTypes.any.isRequired
};

export default ReportContainer;
