import React, { Component } from "react";
import PropTypes from "prop-types";
import { Form, Button, Select, DatePicker, InputNumber, Input } from "antd";
const RangePicker = DatePicker.RangePicker;

const FORM_STATUS = {
  OPEN: "OPEN",
  SUBMITTING: "SUBMITTING",
  SUBMIT_SUCCESS: "SUBMIT_SUCCESS",
  SUBMIT_ERROR: "SUBMIT_ERROR"
};

const PRICE_FORECAST_TYPE = {
  PREDISPATCH: "predispatch",
  P5MIN_PREDISPATCH: "p5min_predispatch",
  DISPATCH: "dispatch"
};

let PRICE_FORECASTS_LIST = [
  {
    name: "Predispatch",
    value: PRICE_FORECAST_TYPE.PREDISPATCH
  },
  {
    name: "P5min and Predispatch",
    value: PRICE_FORECAST_TYPE.P5MIN_PREDISPATCH
  }
  // {
  //   name: "Dispatch",
  //   value: PRICE_FORECAST_TYPE.DISPATCH
  // }
];

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  style: {
    marginBottom: "8px"
  }
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0
    },
    sm: {
      span: 16,
      offset: 8
    }
  }
};
class SolveForm extends Component {
  state = {
    confirmDirty: false,
    formStatus: FORM_STATUS.OPEN,
    assumptionsListFetching: {
      loading: true,
      error: false
    },
    assumptionsList: [],
    selectedAssumption: null,
    selectedFromDate: null,
    selectedToDate: null
  };

  componentDidMount() {
    this.loadAssumptionList();
    const { isDeveloper } = this.props;
    if (isDeveloper)
      PRICE_FORECASTS_LIST.push({
        name: "Dispatch",
        value: PRICE_FORECAST_TYPE.DISPATCH
      });
  }

  loadAssumptionList() {
    const { pocAPI } = this.props;
    pocAPI
      .get("/v1/assumptions?columns=name")
      .then(response => {
        if (response)
          this.setState(prevState => ({
            assumptionsList: response.data,
            assumptionsListFetching: {
              ...prevState.assumptionsListFetching,
              loading: false
            },
            selectedAssumption: response.data[0].name //first value
          }));
      })
      .catch(err => {
        console.log("Error fetching data", err);
        this.setState(prevState => ({
          assumptionsListFetching: {
            ...prevState.assumptionsListFetching,
            loading: false,
            error: true
          }
        }));
      });
  }

  handleSubmit = e => {
    e.preventDefault();
    const { pocAPI, onNewSolve } = this.props;
    const self = this;

    this.props.form.validateFields((err, fieldsValues) => {
      if (!err) {
        self.setState({
          formStatus: FORM_STATUS.SUBMITTING
        });

        const dateRange = fieldsValues["date_range"];
        const dtFormat = "YYYY-MM-DD HH:mm:ss";
        const body = {
          from_date: dateRange[0].format(dtFormat),
          to_date: dateRange[1].format(dtFormat),
          assumption_name: fieldsValues["assumption_name"],
          n_scenarios: fieldsValues["n_scenarios"],
          solve_name: fieldsValues["solve_name"],
          price_forecast: fieldsValues["price_forecast"]
        };
        pocAPI
          .post("/v1/solver/solve", JSON.stringify(body, null, 0), {
            headers: { "Content-Type": "application/json" }
          })
          .then(response => {
            self.setState({ formStatus: FORM_STATUS.SUBMIT_SUCCESS });
            onNewSolve();
          })
          .catch(error =>
            self.setState({ formStatus: FORM_STATUS.SUBMIT_ERROR })
          );
      }
    });
  };

  render() {
    const { form: { getFieldDecorator }, style } = this.props;
    const { assumptionsList, assumptionsListFetching, formStatus } = this.state;

    return (
      <div style={style}>
        <Form layout={"horizontal"} onSubmit={this.handleSubmit}>
          <Form.Item {...formItemLayout} label={"Solve name"}>
            {getFieldDecorator("solve_name", {
              rules: [
                {
                  required: true,
                  message: "Please specify a solve name"
                }
              ]
            })(<Input type={"text"} />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label={"Assumptions"}>
            {getFieldDecorator("assumption_name", {
              rules: [
                {
                  type: "string",
                  required: true,
                  message: "Please select an assumption"
                }
              ]
            })(
              <Select
                loading={assumptionsListFetching.loading}
                style={{ minWidth: "250px" }}
              >
                {assumptionsList.map(item => (
                  <Select.Option key={item.name} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label={"Price Forecast"}>
            {getFieldDecorator("price_forecast", {
              rules: [
                {
                  type: "string",
                  required: true,
                  message: "Please select a price forecast"
                }
              ]
            })(
              <Select loading={false} style={{ minWidth: "200px" }}>
                {PRICE_FORECASTS_LIST.map(item => (
                  <Select.Option key={item.name} value={item.name}>
                    {item.name}
                  </Select.Option>
                ))}
              </Select>
            )}
          </Form.Item>
          <Form.Item {...formItemLayout} label={"Date range"}>
            {getFieldDecorator("date_range", {
              rules: [
                {
                  type: "array",
                  required: true,
                  message: "Please select time"
                }
              ]
            })(<RangePicker showTime format={"YYYY-MM-DD HH:mm:ss"} />)}
          </Form.Item>
          <Form.Item {...formItemLayout} label={"No. of scenarios"}>
            {getFieldDecorator("n_scenarios", {
              initialValue: 1,
              rules: [
                {
                  type: "number",
                  required: true,
                  message: "Please select no. of scenarios"
                }
              ]
            })(<InputNumber disabled />)}
          </Form.Item>
          <Form.Item {...tailFormItemLayout}>
            <Button
              type={"primary"}
              htmlType={"submit"}
              style={{ marginTop: "4px" }}
              loading={formStatus === FORM_STATUS.SUBMITTING}
            >
              Solve
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  }
}

SolveForm.propTypes = {
  pocAPI: PropTypes.func.isRequired,
  onNewSolve: PropTypes.func,
  style: PropTypes.object,
  isDeveloper: PropTypes.bool
};

export default Form.create()(SolveForm);
