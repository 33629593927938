import React, { Component } from "react";
import PropTypes from "prop-types";
import { Chart, ChartHelper } from "components/generic";

/* const colors = [
  "#80d300",
  "#59af00",
  "#499100",
  "#3d7d00",
  "#dc3900",
  "#be3200",
  "#962b00",
  "#7d2600",
  "#ff02ef",
  "#ca00af",
  "#a70090",
  "#850070",
  "#760060",
  "#630050",
  "#00caff",
  "#007ca4",
  "#ffbb01",
  "#cd8f01",
  "#b47c01",
  "#a06d01",
  "#8c5e01",
  "#7d5001",
  "#9500ff",
  "#5f00be",
  "#8c8c8c"
]; */

const seriesDef = [
  { service: "GenEnergy-Revenue", color: "#80d300" },
  { service: "RaiseReg-Revenue", color:  "#59af00" },
  { service: "Raise5min-Revenue", color: "#ffbb01"},
  { service: "Raise60sec-Revenue", color: "#b47c01"},
  { service: "Raise6sec-Revenue", color: "#8c5e01"},
  { service: "LoadEnergy-Revenue", color: "#dc3900"},
  { service: "LowerReg-Revenue", color: "#9500ff"},
  { service: "Lower5min-Revenue", color: "#ff02ef"},
  { service: "Lower60sec-Revenue", color: "#a70090"},
  { service: "Lower6sec-Revenue", color: "#760060"},
  { service: "RaiseReg-Utilisation Revenue", color: "#007ca4"},
/*  { service: "Raise5min-Utilisation Revenue", color: ""},
  { service: "Raise60sec-Utilisation Revenue" , color: ""},
  { service: "Raise6sec-Utilisation Revenue", color: ""}, */
  { service: "LowerReg-Utilisation Revenue", color: "#5f00be"},
/*  { service: "Lower5min-Utilisation Revenue", color: ""},
  { service: "Lower60sec-Utilisation Revenue", color: ""},
  { service: "Lower6sec-UtilisationRevenue", color: ""}, */
  { service: "GenLiability-Revenue", color: "#3d7d00"},
  { service: "LoadLiability-Revenue", color: "#962b00"},
  { service: "CycleCost-Revenue", color: "#8c8c8c"}
];

class ChartRevenueActual extends Component {
  render() {
    const { data, title, chartHeight, loading } = this.props;
    let dataByService = {};
    let netRevenueByDt = {};
    data.forEach(d => {
      const serviceRev = `${d.service.toLowerCase()}-revenue`;
      const serviceUtil = `${d.service.toLowerCase()}-utilisation revenue`;
      if (!dataByService[serviceRev]) dataByService[serviceRev] = [];
      if (!dataByService[serviceUtil]) dataByService[serviceUtil] = [];

      const dt = ChartHelper.formatDateTime(d.settlementdate);
      const rev = parseFloat(d.revenue);
      const utilRev = parseFloat(d.utilisation_revenue);
      let netRev = 0;
      if (!netRevenueByDt[dt]) netRevenueByDt[dt] = 0;

      //add revenue
      if (d.revenue) {
        dataByService[serviceRev].push([dt, rev]);
        netRev += rev;
      }
      //add util revenue
      if (d.utilisation_revenue) {
        dataByService[serviceUtil].push([dt, utilRev]);
        netRev += utilRev;
      }

      //add net revenue t
      netRevenueByDt[dt] += netRev;
    });

    //
    let series = [];
    // const services = Object.keys(dataByService);

    seriesDef.forEach(sd => {
      series.push({
        type: "column",
        stacking: "normal",
        name: sd.service,
        data: dataByService[sd.service.toLowerCase()],
        color: sd.color,
        borderWidth: 0
      });
    });

    //add net revenue line
    series.push({
      name: "Net revenue",
      type: "line",
      color: "#111",
      //negativeColor: "red",
      data: Object.keys(netRevenueByDt).map(dt => [
        parseInt(dt),
        parseFloat(netRevenueByDt[dt])
      ])
    });
    return (
      <div>
        <Chart
          title={title}
          series={series}
          chartHeight={chartHeight}
          yAxisTitle={"Revenue ($)"}
          xAxisTitle={"Settlement date"}
          loading={loading}
        />
      </div>
    );
  }
}

ChartRevenueActual.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
  chartHeight: PropTypes.number,
  loading: PropTypes.bool
};

export default ChartRevenueActual;
