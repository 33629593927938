import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  ChartPhysicalActual,
  ReportView,
  ReportContainer,
  TablePhysicalActual
} from "components";

class ReportPhysicalActual extends Component {
  render() {
    const {
      style,
      socData,
      servicesData,
      title,
      loading,
      selectedReportView
    } = this.props;

    return (
      <ReportContainer style={style}>
        {selectedReportView === ReportView.Table && (
          <TablePhysicalActual
            data={{ soc: socData, services: servicesData }}
            loading={loading}
            title={title}
          />
        )}
        {selectedReportView === ReportView.Chart && (
          <ChartPhysicalActual
            data={{ soc: socData, services: servicesData }}
            loading={loading}
            title={title}
          />
        )}
      </ReportContainer>
    );
  }
}

ReportPhysicalActual.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  socData: PropTypes.array,
  servicesData: PropTypes.array,
  selectedReportView: PropTypes.string.isRequired
};

export default ReportPhysicalActual;
