import React from "react";
import PropTypes from "prop-types";
import { List } from "antd";
import { Error } from "components/generic";
import { SolveDescription } from "components";

const SolvesList = ({
  style,
  title,
  loading,
  error,
  items,
  selectedItem,
  onItemClick,
  nColumns
}) => {
  const onListItemClick = solveId => () => {
    onItemClick(solveId);
  };
  return (
    <div style={style}>
      <h2
        style={{
          fontWeight: "bold",
          color: "rgb(20, 54, 95)",
          padding: "0 16px"
        }}
      >
        {title}
      </h2>
      {error && (
        <Error
          message={"Failed to load Solves list"}
          description={
            "Something went wrong while loading the solves list. Try refreshing the page, if error persists, contact pdView"
          }
        />
      )}
      <List
        itemLayout={"horizontal"}
        loading={loading}
        dataSource={items}
        size={"small"}
        renderItem={(item, i) => (
          <List.Item
            key={item.solve_id}
            onClick={onListItemClick(item.solve_id)}
            style={{
              cursor: "pointer",
              backgroundColor:
                selectedItem === item.solve_id
                  ? "rgba(53, 168, 158, 0.5)"
                  : "#fff",
              padding: "16px"
            }}
          >
            <List.Item.Meta
              description={
                <SolveDescription
                  title={`#${items.length - i}: ${
                    item.solve_name ? item.solve_name : "Untitled"
                  }`}
                  assumptionName={item.assumption_name}
                  fromDate={item.from_date}
                  toDate={item.to_date}
                  runTime={item.run_time}
                  solveId={item.solve_id}
                  nScenarios={item.n_scenarios}
                  priceForecast={item.price_forecast}
                />
              }
            />
          </List.Item>
        )}
      />
    </div>
  );
};

SolvesList.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool.isRequired,
  items: PropTypes.arrayOf(
    PropTypes.shape({
      solve_id: PropTypes.string.isRequired,
      assumption_name: PropTypes.string,
      n_scenarios: PropTypes.string,
      from_date: PropTypes.string.isRequired,
      to_date: PropTypes.string.isRequired
    })
  ),
  selectedItem: PropTypes.string,
  onItemClick: PropTypes.func.isRequired
};

export default SolvesList;
