import React, { Component } from "react";
import moment from "moment";
import PropTypes from "prop-types";
import { Row, Col, Form, Layout, Button } from "antd";
import {
  ReportGeneratedBids,
  ReportPhysicalActual,
  ReportRevenueActual,
  SiderSolveResults,
  ReportViewButtonGroup,
  ReportView,
  ReportPhysicalForecast
} from "components";
import { Error, Selector } from "../../components/generic";

const REPORT = {
  REV_ACT_AGG_DAY: "rev_act_agg_day",
  REV_ACT_5MIN: "rev_act_5min",
  PHY_ACT_5MIN: "phy_act_5min",
  GEN_BID_5MIN: "gen_bid_5min",
  PHY_FOR_SOC: "phy_for_soc"
};

const REPORT_PERIOD = {
  FULL: "full",
  PER_DAY: "per_day",
  PER_5MIN: "per_5min"
};

const REPORTS = [
  {
    text: "Actual Revenue Aggregated Daily",
    value: REPORT.REV_ACT_AGG_DAY,
    report_period: REPORT_PERIOD.FULL
  },
  {
    text: "Actual Revenue 5min (per day)",
    value: REPORT.REV_ACT_5MIN,
    report_period: REPORT_PERIOD.PER_DAY
  },
  {
    text: "Actual Physical 5min (per day)",
    value: REPORT.PHY_ACT_5MIN,
    report_period: REPORT_PERIOD.PER_DAY
  },
  {
    text: "Actual Generated Bids 5min (per day)",
    value: REPORT.GEN_BID_5MIN,
    report_period: REPORT_PERIOD.PER_DAY
  },
  {
    text: "Forecast Physical 5min (per 5min period)",
    value: REPORT.PHY_FOR_SOC,
    report_period: REPORT_PERIOD.PER_5MIN
  }
];

//construct times array, for every 5min interval starting at 00:05 and ending at 00:00
let TIMES = [];
let h = 0;
let m = 5;
for (let p = 0; p < 288; p++) {
  const time = `${h.toString().padStart(2, "0")}:${m
    .toString()
    .padStart(2, "0")}`;
  TIMES.push({
    text: time,
    value: time
  });
  m += 5;
  if (m === 60) {
    h += 1;
    m = 0;
  }
  if (h === 24) h = 0;
}

class Results extends Component {
  state = {
    days: [],
    times: TIMES,
    selectedDay: null,
    selectedTime: "00:05",
    selectedReport: REPORT.REV_ACT_AGG_DAY,
    selectedSolveId: null,
    selectedReportView: ReportView.Chart,
    solveResults: [],
    solveResultsFetching: {
      loading: true,
      error: false
    },
    resultsRevenueActualRaw: [],
    resultsRevenueActualRawFetching: {
      loading: false,
      error: false
    },
    resultsRevenueActualAggDay: [],
    resultsRevenueActualAggDayFetching: {
      loading: false,
      error: false
    },
    resultsPhysicalActualSocRaw: [],
    resultsPhysicalActualSocRawFetching: {
      loading: false,
      error: false
    },
    resultsPhysicalActualServicesRaw: [],
    resultsPhysicalActualServicesRawFetching: {
      loading: false,
      error: false
    },
    resultsGeneratedBidsServicesRaw: [],
    resultsGeneratedBidsServicesRawFetching: {
      loading: false,
      error: false
    },
    resultsPhysicalForecastSoc: [],
    resultsPhysicalForecastSocFetching: {
      loading: false,
      error: false
    },
    pricesForecast: [],
    pricesForecastFetching: {
      loading: false,
      error: false
    },
    bulkDownloadCSVFetching: {
      loading: false,
      error: false
    }
  };

  componentDidMount() {
    const { isDeveloper } = this.props;
    this.loadSolveResults(isDeveloper);
  }

  loadSolveResults(isDeveloper) {
    this.loadDataFromAPI(
      `/v1/solver/solves?is_developer=${isDeveloper}`,
      "solveResults",
      "solveResultsFetching"
    );
  }

  loadDataFromAPI(url, dataKey, dataFetchingKey, callback) {
    const { pocAPI } = this.props;
    const self = this;

    self.setState(
      prevState => ({
        [dataFetchingKey]: {
          ...prevState[dataFetchingKey],
          loading: true
        }
      }),
      () =>
        pocAPI
          .get(url)
          .then(response => {
            self.setState(
              prevState => ({
                [dataKey]: response.data,
                [dataFetchingKey]: {
                  ...prevState[dataFetchingKey],
                  loading: false
                }
              }),
              () => {
                if (callback) callback(); //callback on success only
              }
            );
          })
          .catch(err => {
            console.log(
              `Error fetching data with key: ${dataKey} and url: ${url}`,
              err
            );
            self.setState(prevState => ({
              [dataFetchingKey]: {
                ...prevState[dataFetchingKey],
                loading: false,
                error: true
              }
            }));
          })
    );
  }

  getSolveData = () => {
    const { selectedSolveId, solveResults } = this.state;

    let solveDataObj;
    if (selectedSolveId && solveResults) {
      const match = solveResults.filter(s => s.solve_id === selectedSolveId);
      if (match && match.length > 0) solveDataObj = match[0];
    }
    return solveDataObj;
  };

  getSolveName = () => {
    const solveDataObj = this.getSolveData();
    if (solveDataObj && solveDataObj.solve_name) return solveDataObj.solve_name;
    return "Untitled";
  };

  getDateRangeFull = () => {
    const solveDataObj = this.getSolveData();

    const from_date = moment(solveDataObj.from_date).format(
      "YYYY-MM-DD HH:mm:ss"
    );
    const to_date = moment(solveDataObj.to_date).format("YYYY-MM-DD HH:mm:ss");
    return { from_date, to_date };
  };

  getDateRangeDay = () => {
    const { selectedDay } = this.state;

    const mdate = moment(selectedDay);
    const fromDate = `${mdate.format("YYYY-MM-DD")} 00:05:00`;
    const toDate = `${mdate.add(1, "day").format("YYYY-MM-DD")} 00:00:00`;
    return { fromDate, toDate };
  };

  getDateRange5min = () => {
    const { selectedDay, selectedTime } = this.state;

    return `${selectedDay} ${selectedTime}:00`;
  };

  getReportInfo = report => {
    if (report && REPORTS) {
      const matches = REPORTS.filter(r => r.value === report);
      if (matches && matches.length > 0) return matches[0];
    }
    return null;
  };

  loadSolveData() {
    const { from_date, to_date } = this.getDateRangeFull();

    //load days selector for 5m
    const days = this.getDaysFromDateRange(from_date, to_date);
    const selectedDay = days && days[0] && days[0].value;

    this.setState({
      days: days,
      selectedDay: selectedDay
    });

    //load data for selected report
    this.loadReportData();
  }

  loadReportData = () => {
    if (this.isReportVisible(REPORT.REV_ACT_AGG_DAY)) {
      //load agg by day
      const { from_date, to_date } = this.getDateRangeFull();
      this.loadResultsRevenueActual(
        "day",
        "resultsRevenueActualAggDay",
        "resultsRevenueActualAggDayFetching",
        from_date,
        to_date
      );
    }

    if (this.isReportVisible(REPORT.REV_ACT_5MIN)) {
      const { fromDate, toDate } = this.getDateRangeDay();
      this.loadResultsRevenueActual(
        "raw",
        "resultsRevenueActualRaw",
        "resultsRevenueActualRawFetching",
        fromDate,
        toDate
      );
    }

    if (this.isReportVisible(REPORT.GEN_BID_5MIN)) {
      const { fromDate, toDate } = this.getDateRangeDay();
      this.loadResultsGeneratedBidsServicesRaw(fromDate, toDate);
    }

    if (this.isReportVisible(REPORT.PHY_ACT_5MIN)) {
      const { fromDate, toDate } = this.getDateRangeDay();
      this.loadResultsPhysicalActualSocRaw(fromDate, toDate);
      this.loadResultsPhysicalActualServicesRaw(fromDate, toDate);
    }

    if (this.isReportVisible(REPORT.PHY_FOR_SOC)) {
      const dt = this.getDateRange5min();
      this.loadResultsPhysicalForecastSoc(dt);
      this.loadPricesForecast(dt);
    }
  };

  loadResultsRevenueActual = (
    agg,
    dataKey,
    dataFetchingKey,
    fromDate,
    toDate
  ) => {
    const { selectedSolveId } = this.state;

    this.loadDataFromAPI(
      `/v1/results/revenue/actual?from_settlementdate=${fromDate}&to_settlementdate=${toDate}&solve_id=${selectedSolveId}&aggregation=${agg}`,
      dataKey,
      dataFetchingKey
    );
  };

  loadResultsPhysicalActualSocRaw = (fromDate, toDate) => {
    const { selectedSolveId } = this.state;

    this.loadDataFromAPI(
      `/v1/results/physical/actual/soc?from_settlementdate=${fromDate}&to_settlementdate=${toDate}&solve_id=${selectedSolveId}`,
      "resultsPhysicalActualSocRaw",
      "resultsPhysicalActualSocRawFetching"
    );
  };

  loadResultsPhysicalActualServicesRaw = (fromDate, toDate) => {
    const { selectedSolveId } = this.state;
    this.loadDataFromAPI(
      `/v1/results/physical/actual/services?from_settlementdate=${fromDate}&to_settlementdate=${toDate}&solve_id=${selectedSolveId}`,
      "resultsPhysicalActualServicesRaw",
      "resultsPhysicalActualServicesRawFetching"
    );
  };

  loadResultsGeneratedBidsServicesRaw = (fromDate, toDate) => {
    const { selectedSolveId } = this.state;
    this.loadDataFromAPI(
      `/v1/results/generatedbids/services?from_settlementdate=${fromDate}&to_settlementdate=${toDate}&solve_id=${selectedSolveId}`,
      "resultsGeneratedBidsServicesRaw",
      "resultsGeneratedBidsServicesRawFetching"
    );
  };

  loadResultsPhysicalForecastSoc = settlementDate => {
    const { selectedSolveId } = this.state;
    this.loadDataFromAPI(
      `/v1/results/physical/forecast/soc?settlementdate=${settlementDate}&solve_id=${selectedSolveId}&scenario=1`,
      "resultsPhysicalForecastSoc",
      "resultsPhysicalForecastSocFetching"
    );
  };

  loadPricesForecast = settlementDate => {
    const { selectedSolveId } = this.state;
    this.loadDataFromAPI(
      `/v1/prices/forecast?settlementdate=${settlementDate}&solve_id=${selectedSolveId}&scenario=1`,
      "pricesForecast",
      "pricesForecastFetching"
    );
  };

  isReportVisible = report => {
    const { selectedReport } = this.state;
    return selectedReport === report;
  };

  getDaysFromDateRange = (fromDate, toDate) => {
    let days = [];
    let mFromDate = moment(fromDate);
    let mToDate = moment(toDate);
    while (mFromDate < mToDate) {
      const day = mFromDate.format("YYYY-MM-DD");
      days.push({ text: day, value: day });
      mFromDate = mFromDate.add(1, "day");
    }
    return days;
  };

  onChangeSelectedDay = day => {
    this.setState(
      {
        selectedDay: day
      },
      () => this.loadReportData()
    );
  };

  onChangeSelectedTime = time => {
    this.setState(
      {
        selectedTime: time
      },
      () => this.loadReportData()
    );
  };

  onChangeSolveId = solveId => {
    this.setState(
      {
        selectedSolveId: solveId
      },
      () => this.loadSolveData()
    );
  };

  onChangeReportView = view => {
    this.setState({
      selectedReportView: view
    });
  };

  onChangeReport = report => {
    this.setState(
      {
        selectedReport: report
      },
      () => this.loadReportData()
    );
  };

  onClickBulkDownloadCSV = () => {
    const { selectedSolveId } = this.state;
    const { from_date, to_date } = this.getDateRangeFull();
    const { pocAPI } = this.props;
    const solveName = encodeURIComponent(this.getSolveName());
    const self = this;
    const url = `/v1/results/revenue/actual/all/csvlink?solve_id=${selectedSolveId}&from_settlementdate=${from_date}&to_settlementdate=${to_date}&solve_name=${solveName}`;
    const dataFetchingKey = "bulkDownloadCSVFetching";
    self.setState(
      prevState => ({
        [dataFetchingKey]: {
          ...prevState[dataFetchingKey],
          loading: true
        }
      }),
      () =>
        pocAPI
          .get(url)
          .then(response => {
            //download response url and create temp link and click it
            const link = document.createElement("a");
            link.href = `${response.data.url}`;
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link); //clean up and remove the link

            //update state
            self.setState(prevState => ({
              [dataFetchingKey]: {
                ...prevState[dataFetchingKey],
                loading: false
              }
            }));
          })
          .catch(err => {
            console.log(
              `Error fetching data for bulk download csv with url: ${url}`,
              err
            );
            self.setState(prevState => ({
              [dataFetchingKey]: {
                ...prevState[dataFetchingKey],
                loading: false,
                error: true
              }
            }));
          })
    );
  };

  render() {
    const {
      days,
      times,
      selectedDay,
      selectedTime,
      selectedReport,
      selectedSolveId,
      selectedReportView,
      solveResults,
      solveResultsFetching,
      resultsRevenueActualRaw,
      resultsRevenueActualRawFetching,
      resultsRevenueActualAggDay,
      resultsRevenueActualAggDayFetching,
      resultsPhysicalActualSocRaw,
      resultsPhysicalActualSocRawFetching,
      resultsPhysicalActualServicesRaw,
      resultsPhysicalActualServicesRawFetching,
      pricesForecast,
      pricesForecastFetching,
      resultsPhysicalForecastSoc,
      resultsPhysicalForecastSocFetching,
      resultsGeneratedBidsServicesRaw,
      resultsGeneratedBidsServicesRawFetching,
      bulkDownloadCSVFetching
    } = this.state;

    const selectedReportInfo = this.getReportInfo(selectedReport);
    const selectedReportPeriod = selectedReportInfo.report_period;

    return (
      <Layout>
        <SiderSolveResults
          loading={solveResultsFetching.loading}
          error={solveResultsFetching.error}
          data={solveResults}
          selectedSolveId={selectedSolveId}
          onChangeSolveId={this.onChangeSolveId}
        />
        <Layout>
          <Layout.Content style={{ padding: "24px" }}>
            {!selectedSolveId ? (
              <Row>
                <Col span={6}>&nbsp;</Col>
                <Col span={12}>
                  <Error
                    message={"Please select a solve run"}
                    description={
                      "Please pick a solve from left side to view results"
                    }
                  />
                </Col>
              </Row>
            ) : (
              <div style={{ marginLeft: "24px" }}>
                <Button
                  type={"primary"}
                  icon={bulkDownloadCSVFetching.error ? "warning" : "download"}
                  style={{ float: "right" }}
                  title={"Download detailed CSV"}
                  onClick={this.onClickBulkDownloadCSV}
                  loading={bulkDownloadCSVFetching.loading}
                >
                  Bulk download CSV
                </Button>
                <Form layout={"inline"}>
                  <Form.Item label={"Report"}>
                    <Selector
                      loading={false}
                      onChangeSelectedValue={this.onChangeReport}
                      selectedValue={selectedReport}
                      items={REPORTS}
                      style={{ minWidth: "300px" }}
                    />
                  </Form.Item>
                  <Form.Item label={"Day"}>
                    <Selector
                      loading={false}
                      onChangeSelectedValue={this.onChangeSelectedDay}
                      items={days}
                      selectedValue={selectedDay}
                      style={{ minWidth: "100px" }}
                      disabled={selectedReportPeriod === REPORT_PERIOD.FULL}
                    />
                  </Form.Item>
                  <Form.Item label={"Time"}>
                    <Selector
                      loading={false}
                      onChangeSelectedValue={this.onChangeSelectedTime}
                      items={times}
                      selectedValue={selectedTime}
                      style={{ minWidth: "90px" }}
                      disabled={selectedReportPeriod !== REPORT_PERIOD.PER_5MIN}
                    />
                  </Form.Item>
                  <Form.Item label={"View"}>
                    <ReportViewButtonGroup
                      onChange={this.onChangeReportView}
                      selectedValue={selectedReportView}
                    />
                  </Form.Item>
                </Form>
                {this.isReportVisible(REPORT.REV_ACT_AGG_DAY) && (
                  <ReportRevenueActual
                    title={this.getReportInfo(REPORT.REV_ACT_AGG_DAY).text}
                    data={resultsRevenueActualAggDay}
                    loading={resultsRevenueActualAggDayFetching.loading}
                    selectedReportView={selectedReportView}
                  />
                )}
                {this.isReportVisible(REPORT.REV_ACT_5MIN) && (
                  <ReportRevenueActual
                    title={this.getReportInfo(REPORT.REV_ACT_5MIN).text}
                    data={resultsRevenueActualRaw}
                    loading={resultsRevenueActualRawFetching.loading}
                    selectedReportView={selectedReportView}
                  />
                )}
                {this.isReportVisible(REPORT.GEN_BID_5MIN) && (
                  <ReportGeneratedBids
                    title={this.getReportInfo(REPORT.GEN_BID_5MIN).text}
                    data={resultsGeneratedBidsServicesRaw}
                    loading={resultsGeneratedBidsServicesRawFetching.loading}
                    selectedReportView={selectedReportView}
                  />
                )}
                {this.isReportVisible(REPORT.PHY_ACT_5MIN) && (
                  <ReportPhysicalActual
                    title={this.getReportInfo(REPORT.PHY_ACT_5MIN).text}
                    socData={resultsPhysicalActualSocRaw}
                    servicesData={resultsPhysicalActualServicesRaw}
                    loading={
                      resultsPhysicalActualSocRawFetching.loading ||
                      resultsPhysicalActualServicesRawFetching.loading
                    }
                    selectedReportView={selectedReportView}
                  />
                )}
                {this.isReportVisible(REPORT.PHY_FOR_SOC) && (
                  <ReportPhysicalForecast
                    title={this.getReportInfo(REPORT.PHY_FOR_SOC).text}
                    socData={resultsPhysicalForecastSoc}
                    pricesData={pricesForecast}
                    loading={
                      resultsPhysicalForecastSocFetching.loading ||
                      pricesForecastFetching.loading
                    }
                    selectedReportView={selectedReportView}
                  />
                )}
              </div>
            )}
          </Layout.Content>
        </Layout>
      </Layout>
    );
  }
}

Results.propTypes = {
  pocAPI: PropTypes.func,
  isDeveloper: PropTypes.bool
};

export default Results;
