import React from "react";
import PropTypes from "prop-types";

const rowStyle = {
  padding: "4px"
};

const labelStyle = {
  fontWeight: "bold",
  color: "#222",
  margin: 0,
  padding: 0,
  minWidth: "100px"
};

const valueStyle = {};

const SolveDescription = ({
  style,
  title,
  assumptionName,
  fromDate,
  toDate,
  runTime,
  nScenarios,
  solveId,
  priceForecast
}) => {
  return (
    <div style={style}>
      <h2 style={{ color: "#14365f" }}>{title}</h2>
      <table style={{ color: "#333" }}>
        <tbody>
          <tr style={rowStyle}>
            <td>
              <label style={labelStyle}>Assumption: </label>
            </td>
            <td>
              <span style={valueStyle}>{assumptionName}</span>
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>
              <label style={labelStyle}>Price forecast: </label>
            </td>
            <td>
              <span style={valueStyle}>{priceForecast}</span>
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>
              <label style={labelStyle}>From date: </label>
            </td>
            <td>
              <span style={valueStyle}>{fromDate}</span>
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>
              <label style={labelStyle}>To date: </label>
            </td>
            <td>
              <span style={valueStyle}>{toDate}</span>
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>
              <label style={labelStyle}>Run time: </label>
            </td>
            <td>
              <span style={valueStyle}>{runTime}</span>
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>
              <label style={labelStyle}>Scenarios: </label>
            </td>
            <td>
              <span style={valueStyle}>{nScenarios}</span>
            </td>
          </tr>
          <tr style={rowStyle}>
            <td>
              <label style={labelStyle}>Solve Id: </label>
            </td>
            <td>
              <span style={valueStyle}>{solveId}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};

SolveDescription.propTypes = {
  style: PropTypes.object,
  title: PropTypes.string,
  assumptionName: PropTypes.string,
  fromDate: PropTypes.string,
  toDate: PropTypes.string,
  runTime: PropTypes.string,
  nScenarios: PropTypes.string,
  solveId: PropTypes.string,
  priceForecast: PropTypes.string
};

export default SolveDescription;
