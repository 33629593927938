import React from "react";
import { object, array, bool, func } from "prop-types";
import { List } from "antd";
import { Error } from "components/generic";
import { ProgressListItem } from "../progress_list_item";

const ProgressList = ({ style, error, items, loading, pocAPI }) => (
  <div
    style={{
      backgroundColor: "#fff",
      borderRadius: "4px",
      padding: "16px",
      ...style
    }}
  >
    <h2
      style={{
        fontWeight: "bold",
        color: "rgb(20, 54, 95)",
        padding: "0 16px"
      }}
    >
      Solves Progress
    </h2>
    {error && (
      <Error
        message={"Failed to load Solves list"}
        description={
          "Something went wrong while loading the solves list. Try refreshing the page, if error persists, contact pdView"
        }
      />
    )}
    <List
      itemLayout={"horizontal"}
      loading={loading}
      dataSource={items}
      size={"small"}
      renderItem={(item, i) => (
        <ProgressListItem
          key={item.solve_id}
          pocAPI={pocAPI}
          solveId={item.solve_id}
          fromDate={item.from_date}
          toDate={item.to_date}
          solveName={item.solve_name ? item.solve_name : "Untitled"}
          runTime={item.run_time}
          solveNumber={items.length - i}
        />
      )}
    />
  </div>
);

ProgressList.propTypes = {
  style: object,
  items: array,
  pocAPI: func.isRequired,
  loading: bool.isRequired,
  error: bool.isRequired
};

export default ProgressList;
