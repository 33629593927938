import React, { Component } from "react";
import { func, object, string, number } from "prop-types";
import { Progress, Row, Col, List, Skeleton, Button } from "antd";

class ProgressListItem extends Component {
  state = {
    progressFetching: {
      loading: false,
      error: false
    },
    progress: []
  };

  componentDidMount() {
    this.loadProgress();
  }

  loadProgress() {
    const { pocAPI, solveId } = this.props;
    this.setState(
      prevState => ({
        progressFetching: {
          ...prevState.progressFetcing,
          loading: true
        }
      }),
      () =>
        pocAPI
          .get(`/v1/solver/solveprogress/${solveId}`)
          .then(response => {
            if (response)
              this.setState(prevState => ({
                progress: response.data,
                progressFetching: {
                  ...prevState.progressFetching,
                  loading: false
                }
              }));
          })
          .catch(err => {
            console.log("Error fetching data", err);
            this.setState(prevState => ({
              progressFetching: {
                ...prevState.progressFetching,
                loading: false,
                error: true
              }
            }));
          })
    );
  }

  onClickListItemRefresh = e => {
    this.loadProgress();
  };

  render() {
    const { progress, progressFetching } = this.state;
    const {
      fromDate,
      toDate,
      solveName,
      solveNumber,
      solveId,
      runTime
    } = this.props;

    const item =
      progressFetching && progressFetching.loading === false
        ? progress && progress[0]
        : null;

    let percent = 0;
    if (item && item.found_count !== null && item.expected_count !== null) {
      percent = parseFloat(
        (item.found_count / item.expected_count * 100).toFixed(2)
      );
    }
    return (
      <List.Item
        key={solveId}
        style={{ padding: "16px" }}
        actions={[
          <Button
            title={"Refresh"}
            type={"primary"}
            size={"small"}
            shape={"circle"}
            icon={"sync"}
            onClick={this.onClickListItemRefresh}
          />
        ]}
      >
        <Skeleton
          loading={progressFetching.loading}
          active={true}
          avatar={{ size: "large" }}
        >
          <List.Item.Meta
            title={
              <span
                style={{ fontSize: "16px", fontWeight: "bold" }}
              >{`#${solveNumber}: ${solveName}`}</span>
            }
            avatar={<Progress type={"circle"} percent={percent} width={80} />}
            description={
              <div>
                <Row style={{ margin: "16px 0" }}>
                  <Col sm={6}>
                    <b>From Date:</b> {fromDate}
                  </Col>
                  <Col sm={6}>
                    <b>To Date:</b> {toDate}
                  </Col>
                  <Col sm={6}>
                    <b>Run Time:</b> {runTime}
                  </Col>
                  <Col sm={6}>
                    <b>Solve Id: </b>
                    {solveId}
                  </Col>
                </Row>
                {item ? (
                  <div>
                    <Row>
                      <Col sm={6}>
                        <b>Min Sett Date: </b> {item.min_found_settdate}
                      </Col>
                      <Col sm={6}>
                        <b>Max Sett Date: </b> {item.max_found_settdate}
                      </Col>
                      <Col sm={6}>
                        <b>Counts:</b> {item.found_count} /{" "}
                        {item.expected_count}
                      </Col>
                    </Row>
                  </div>
                ) : (
                  <div>--</div>
                )}
              </div>
            }
          />
        </Skeleton>
      </List.Item>
    );
  }
}

ProgressListItem.propTypes = {
  style: object,
  pocAPI: func.isRequired,
  solveId: string.isRequired,
  solveNumber: number.isRequired,
  solveName: string.isRequired,
  fromDate: string.isRequired,
  toDate: string.isRequired,
  runTime: string.isRequired
};

export default ProgressListItem;
