import React from "react";
import PropTypes from "prop-types";
import { Select } from "antd";

const Selector = ({
  items,
  selectedValue,
  onChangeSelectedValue,
  loading,
  error,
  disabled,
  style
}) => {
  return (
    <div>
      {error && <span>Failed to load</span>}
      <Select
        style={style}
        loading={loading}
        value={selectedValue}
        onChange={value => onChangeSelectedValue(value)}
        disabled={disabled}
      >
        {items.map(item => (
          <Select.Option key={item.value} value={item.value}>
            {item.text}
          </Select.Option>
        ))}
      </Select>
    </div>
  );
};

Selector.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      text: PropTypes.string.isRequired
    })
  ),
  selectedValue: PropTypes.string,
  onChangeSelectedValue: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  error: PropTypes.bool,
  style: PropTypes.object,
  disabled: PropTypes.bool
};
export default Selector;
