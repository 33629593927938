import React from "react";
import PropTypes from "prop-types";
import { Table, TableHelper } from "components/generic";
import { ReportContainer } from "../report_container";

const TableGeneratedBids = ({ style, loading, title, data }) => {
  const renderCell = (record, key, service, style) => {
    const settDt = record.settDt;
    const value = dataSourceByService[service][settDt][key];
    return TableHelper.formatCell(value, style, 0);
  };

  let dataSourceByService = {};

  let columnBidBandsByService = {};

  data.forEach(row => {
    const settDt = row.settlementdate;
    const service = row.service.toLowerCase();
    const bidPrice = row.bid_price;
    const bidVolume = row.bid_volume;

    if (!dataSourceByService[service]) dataSourceByService[service] = {};
    if (!dataSourceByService[service][settDt])
      dataSourceByService[service][settDt] = {};
    if (!dataSourceByService[service][settDt][`bid-band-${bidPrice}`])
      dataSourceByService[service][settDt][`bid-band-${bidPrice}`] = bidVolume;

    if (!columnBidBandsByService[service])
      columnBidBandsByService[service] = [];

    if (columnBidBandsByService[service].indexOf(bidPrice) < 0)
      columnBidBandsByService[service].push(bidPrice);
  });

  //let services = Object.keys(columnBidBandsByService);
  const services = [
    "GenEnergy",
    "LoadEnergy",
    "Raisereg",
    "Raise5min",
    "Raise60sec",
    "Raise6sec",
    "Lowerreg",
    "Lower5min",
    "Lower60sec",
    "Lower6sec"
  ];

  let columnsByService = {};
  services.forEach(service => {
    if (!columnsByService[service.toLowerCase()]) {
      columnsByService[service.toLowerCase()] = [
        {
          title: TableHelper.formatHeader("Settlement date"),
          dataIndex: "settDt",
          key: "settDt",
          fixed: "left",
          width: 200
        },
        {
          title: TableHelper.formatHeader("Bid bands MW"),
          key: "bid-bands",
          children: columnBidBandsByService[service.toLowerCase()]
            ? columnBidBandsByService[service.toLowerCase()].map(bidPrice => ({
                title: TableHelper.formatHeader(
                  TableHelper.formatCell(bidPrice, { color: "default" }, 2)
                ),
                key: bidPrice,
                width: 100,
                align: "right",
                render: (text, record) =>
                  renderCell(
                    record,
                    `bid-band-${bidPrice}`,
                    service.toLowerCase()
                  )
              }))
            : []
        }
      ];
    }
  });

  return (
    <div style={style}>
      {services.map(service => (
        <ReportContainer key={service}>
          <Table
            title={`${title} - ${service}`}
            loading={loading}
            dataSource={
              dataSourceByService[service.toLowerCase()]
                ? Object.keys(dataSourceByService[service.toLowerCase()]).map(
                    dt => {
                      return {
                        key: `row-${service.toLowerCase()}-${dt}`,
                        settDt: dt
                      };
                    }
                  )
                : null
            }
            columns={columnsByService[service.toLowerCase()]}
          />
        </ReportContainer>
      ))}
    </div>
  );
};

TableGeneratedBids.propTypes = {
  style: PropTypes.object,
  loading: PropTypes.bool,
  data: PropTypes.array,
  title: PropTypes.string
};

export default TableGeneratedBids;
