import React from "react";
import commaNumber from "comma-number";


export class TableHelper {
  static formatHeader(text) {
    return (
      <span style={{ color: "rgb(20, 54, 95)", fontWeight: "bold" }}>
        {text}
      </span>
    );
  }

  static formatCell(value, style, decimalPlaces = 2) {
    let color = "#ccc";
    if (value > 0) color = "green";
    else if (value < 0) color = "red";
    return <span style={{ color: color, ...style }}>{value !== null && value !== undefined ? commaNumber(parseFloat(value).toFixed(decimalPlaces)): '-'}</span>;
  }
}
